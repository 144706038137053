<template>
    <v-container :fluid="$vuetify.breakpoint.md">
        <v-row justify="center">
            <v-col cols="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-0"
                                  style="word-break: normal">CONVERSIÓN DE DIVISAS
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-card flat class="pa-0" :class="!$props.user ? 'mb-14' : ''">
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <v-row justify="center">
                        <v-col cols="12" class="py-2">
                            <h3 class="text-center grey--text text--darken-1">SELECCIONE PAISES</h3>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                            <v-skeleton-loader class="my-2 mx-auto" width="100%" type="list-item-avatar" v-if="loading === true"/>
                            <v-select
                                :label="labels.country"
                                :items="countries"
                                name="country"
                                filled
                                hide-details
                                v-model="form.country"
                                :error-messages="errors.country"
                                :disabled="loading"
                                :rules="[rules.required('country')]"
                                @select="clearErrors('country')"
                                item-text="name"
                                item-value="code"
                                v-else>
                                <template v-slot:selection="{ item }">
                                    <v-flex>
                                        <v-avatar size="28px">
                                            <img :src="'/img/country/' + item.code + '.png'" :alt="item.name.toLowerCase()" />
                                        </v-avatar>
                                        <span class='ml-1'>{{ $vuetify.breakpoint.xsOnly ? item.code : item.name }}</span>
                                    </v-flex>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-list-item-avatar>
                                        <img :src="'/img/country/' + item.code + '.png'" :alt="item.name.toLowerCase()" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="item.name"/>
                                    </v-list-item-content>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                            <v-skeleton-loader class="my-2 mx-auto" width="100%" type="list-item-avatar" v-if="loading === true"/>
                            <v-select
                                :label="labels.destiny"
                                :items="destinations"
                                name="destiny"
                                filled
                                hide-details
                                v-model="form.destiny"
                                :error-messages="errors.destiny"
                                :disabled="loading"
                                :rules="[rules.required('destiny')]"
                                @select="clearErrors('destiny')"
                                item-text="name"
                                item-value="code"
                                v-else>
                                <template v-slot:selection="{ item }">
                                    <v-flex>
                                        <v-avatar size="28px">
                                            <img :src="'/img/country/' + item.code + '.png'" :alt="item.name.toLowerCase()" />
                                        </v-avatar>
                                        <span class='ml-1'>{{ $vuetify.breakpoint.xsOnly ? item.code : item.name }}</span>
                                    </v-flex>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-list-item-avatar>
                                        <img :src="'/img/country/' + item.code + '.png'" :alt="item.name.toLowerCase()" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="item.name"/>
                                    </v-list-item-content>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6">
                    <v-row justify="center">
                        <v-col cols="12" class="py-2">
                            <h3 class="text-center grey--text text--darken-1">TU ENVIAS</h3>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                            <v-skeleton-loader class="my-2 mx-auto" width="100%" type="list-item-avatar" v-if="loading === true"/>
                            <v-select
                                :label="labels.currency"
                                :items="cs"
                                name="cSend"
                                v-model="form.cSend"
                                :error-messages="errors.cSend"
                                :disabled="loading"
                                :rules="[rules.req('currency')]"
                                @select="clearErrors('cSend')"
                                item-text="name"
                                item-value="code"
                                hide-details
                                filled
                                v-else>
                                <template v-slot:selection="{ item }">
                                    <v-flex>
                                        <v-avatar size="28px" color="primary">
                                            <span class="warning--text subtitle-2 text-center" style="width: 100%">{{ item.symbol }}</span>
                                        </v-avatar>
                                        <span class='ml-1'>{{ $vuetify.breakpoint.xsOnly ? item.code : item.name }}</span>
                                    </v-flex>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-list-item-avatar color="primary">
                                        <span class="warning--text subtitle-1 text-center" style="width: 100%">
                                            <strong>{{ item.symbol }}</strong>
                                        </span>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="item.name"/>
                                    </v-list-item-content>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                            <v-skeleton-loader class="my-2 mx-auto" width="100%" type="list-item-avatar" v-if="loading === true"/>
                            <v-currency-field
                                :label="labels.value"
                                v-model="form.amount"
                                name="amount"
                                filled
                                hide-details
                                :error-messages="errors.amount"
                                :rules="[rules.required('value'), rules.minus('value', form.amount, min, 'El'), rules.maxim('value', form.amount, max, 'El')]"
                                :disabled="loading"
                                @input="clearErrors('amount')"
                                v-bind="currency_config"
                                v-else/>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row justify="center">
                        <v-col cols="12" sm="6" class="pb-0">
                            <v-skeleton-loader class="my-4 mx-auto" width="100%" height="60px" type="image" style="border: 1px solid #ddd; border-radius: 5px" v-if="loading === true"/>
                            <div class="pa-3 bg text-center primary--text" style="border: 1px solid #ddd; border-radius: 5px" v-else>
                                <span>
                                    <strong><v-icon>mdi-star-circle</v-icon> Costo de Envío:</strong>
                                    <br v-show="$vuetify.breakpoint.xsOnly">
                                    {{ form.feeIn ? Number(form.feeIn).toLocaleString("de-DE") : 0 }} {{ form.cSend }}
                                </span>
                                <br>
                                <span>
                                    <strong><v-icon>mdi-sync-circle</v-icon> Monto a Convertir:</strong>
                                    <br v-show="$vuetify.breakpoint.xsOnly">
                                    {{ form.vSend ? Number(form.vSend).toLocaleString("de-DE") : 0 }} {{ form.cSend }}
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" class="pb-0" v-show="$vuetify.breakpoint.smAndUp">
                            <v-skeleton-loader class="my-4 mx-auto" width="100%" height="60px" type="image" style="border: 1px solid #ddd; border-radius: 5px" v-if="loading === true"/>
                            <div class="pa-3 bg text-center primary--text" style="border: 1px solid #ddd; border-radius: 5px" v-else>
                                <span>
                                    <strong><v-icon>mdi-minus-circle</v-icon> Monto Mínimo:</strong>
                                    <br v-show="$vuetify.breakpoint.xsOnly">
                                    {{ min ? Number(min).toLocaleString("de-DE") : 0 }} {{ form.cSend }}
                                </span>
                                <br>
                                <span>
                                    <strong><v-icon>mdi-plus-circle</v-icon> Monto Máximo:</strong>
                                    <br v-show="$vuetify.breakpoint.xsOnly">
                                    {{ max ? Number(max).toLocaleString("de-DE") : 0 }} {{ form.cSend }}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row justify="center">
                        <v-col cols="12" class="py-2">
                            <h3 class="text-center grey--text text--darken-1">TU DESTINATARIO RECIBE</h3>
                        </v-col>
                        <template v-for="item in quotes">
                            <v-col cols="6" md="3" align-self="center">
                                <v-skeleton-loader class="mb-2 mx-auto" width="100%" type="button" v-if="loading === true"/>
                                <v-card outlined class="bg primary--text text-center" @click="getConvert(item)" v-else>
                                    <v-card-title class="d-block px-2 py-1">{{ Number(item.value).toLocaleString("de-DE") + ' ' + item.currency }}</v-card-title>
                                    <v-divider/>
                                    <v-card-text class="pa-2">
                                        <h3 class="mb-1" v-show="form.destiny === 'VEN'"><v-icon>mdi-bank-circle</v-icon>{{ item.bank }}</h3>
                                        <p class="mb-0">
                                            <strong><v-icon>mdi-minus-circle</v-icon>Costo de Pago</strong><strong v-show="$vuetify.breakpoint.smAndUp">:</strong>
                                            <br v-show="$vuetify.breakpoint.xsOnly">
                                            <span>{{ Number(item.fee).toLocaleString("de-DE") }} {{ item.currency }}</span>
                                        </p>
                                        <p class="mb-0">
                                            <strong><v-icon>mdi-percent-circle</v-icon>Tasa de Cambio</strong><strong v-show="$vuetify.breakpoint.smAndUp">:</strong>
                                            <br v-show="$vuetify.breakpoint.xsOnly">
                                            <span>{{ Number(item.fx).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 6}) }}</span>
                                        </p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row justify="center" v-show="$vuetify.breakpoint.xsOnly">
                        <v-col cols="12" sm="6" class="py-0">
                            <v-skeleton-loader class="my-4 mx-auto" width="100%" height="58px" type="image"
                                               style="border: 1px solid #ddd; border-radius: 5px" v-if="loading === true"/>
                            <div class="pa-3 bg text-center primary--text" style="border: 1px solid #ddd; border-radius: 5px" v-else>
                                <span>
                                    <strong><v-icon>mdi-minus-circle</v-icon> Monto Mínimo:</strong>
                                    <br v-show="$vuetify.breakpoint.xsOnly">
                                    {{ min ? Number(min).toLocaleString("de-DE") : 0 }} {{ form.cSend }}
                                </span>
                                <br>
                                <span>
                                    <strong><v-icon>mdi-plus-circle</v-icon> Monto Máximo:</strong>
                                    <br v-show="$vuetify.breakpoint.xsOnly">
                                    {{ max ? Number(max).toLocaleString("de-DE") : 0 }} {{ form.cSend }}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" sm="10" md="8" class="pb-2">
                            <h4 class="text-center" v-if="loading !== true">El resultado no contiene comisiones bancarias</h4>
                        </v-col>
                    </v-row>
                    <v-row justify="center" v-if="$props.user && $props.user.role === 'Customer'">
                        <v-col cols="12" md="10" lg="8" class="text-center">
                            <v-btn color="primary" :to="{name: 'Request'}">
                                <v-icon>mdi-cash-100</v-icon>&nbsp;&nbsp;ENVIAR DINERO
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <v-bottom-navigation grow fixed dark background-color="primary" color="amber lighten-1" v-if="!$props.user">
            <v-btn class="px-1" to="/login">
                <span>Iniciar Sesión</span>
                <v-icon>mdi-account-key</v-icon>
            </v-btn>
            <v-btn class="px-1" to="/register">
                <span>Registro</span>
                <v-icon>mdi-account-plus</v-icon>
            </v-btn>
            <v-btn class="px-1" to="/calculator/public">
                <span>Cotizador</span>
                <v-icon>mdi-calculator-variant</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-container>
</template>


<script>
import axios from "axios";
import Form from "../helpers/form";

export default {
    name: "Edit",
    mixins: [Form],
    props: ['user'],
    data: () => ({
        loading: true,
        countries: [
            { name: 'Venezuela', code: 'VEN' },
            { name: 'Colombia', code: 'COL' },
            { name: 'Perú', code: 'PER' },
            { name: 'Chile', code: 'CHL' },
            { name: 'Ecuador', code: 'ECU' },
            { name: 'Brasil', code: 'BRA' },
            { name: 'México', code: 'MEX' },
            { name: 'Panamá', code: 'PAN' },
            { name: 'Estados Unidos', code: 'USA' }
        ],
        destinations: [],
        banks: [],
        cs: [],
        cr: [],
        rates: [],
        fees: {},
        limits: {},
        quotes: [],
        labels: {
            country: 'Pais Origen',
            destiny: 'Pais Destino',
            value: 'Valor',
            currency: 'Moneda'
        },
        form: {
            country: '',
            destiny: '',
            amount: '',
            vSend: '',
            cSend: '',
            fx: '',
            feeIn: '',
            feeOut: '',
            vReceiver: '',
            cReceiver: '',
            quote: ''
        },
        min: null,
        max: null,
        currency_config: {
            locale: 'de-DE',
            decimalLength: 2,
            autoDecimalMode: false,
            min: null,
            max: null,
            defaultValue: 0,
            valueAsInteger: false,
            allowNegative: false
        },
    }),
    mounted() {
        this.getIni();
    },
    watch: {
        'user.country'() {
            this.getIni();
        },
        'form.country'() {
            if (this.form.country) {
                let d = [];
                let c = this.countries;
                for (let i = 0; i < c.length; i++) {
                    if (c[i].code !== this.form.country) {
                        d.push(c[i]);
                    }
                }
                this.destinations = d;
                if (this.form.country === this.form.destiny || this.form.destiny === '') {
                    this.form.destiny = d[0].code;
                } else {
                    this.getData();
                }
            }
        },
        'form.destiny'() {
            if (this.form.destiny) {
                this.getBanks();
                this.getData();
            }
        },
        'form.amount'() {
            if (this.form.amount) {
                this.getParams();
            }
        },
        'form.vSend'() {
            if (this.form.vSend) {
                this.calculator();
            }
        },
        'form.cSend'() {
            if (this.form.cSend) {
                this.getParams();
            }
        },
        min() {
            if (this.min) {
                this.form.amount = this.min;
            }
        }
    },
    methods: {
        getIni() {
            if (this.$props.user) {
                this.form.country = this.$props.user.country;
            } else {
                this.form.country = 'COL';
            }

        },
        getData() {
            let c = this.form.country;
            let d = this.form.destiny;
            axios.get('/api/currencies/' + c + '/' + d).then((res) => {
                this.cs = res.data.cs;
                this.cr = res.data.cr;
                this.rates = res.data.rates;
                this.fees = res.data.fees;
                this.limits = res.data.limits;
                this.form.cSend = this.cs[0].code;
            }).catch(err => {
                this.handleErrors(err.response.data.errors);
            }).then(() => {
                this.getParams();
                this.loading = false;
            });
            this.timeout = setTimeout(this.getData, 300000);
        },
        getBanks() {
            let d = this.form.destiny;
            axios.get('/api/banks/' + d).then((res) => {
                this.banks = res.data.banks;
            }).catch(err => {
                this.handleErrors(err.response.data.errors);
            });
        },
        getParams() {
            let fee = 0;
            let cs = this.cs;
            let fees = this.fees;
            let limits = this.limits;
            let mins = limits['min'];
            let maxs = limits['max'];
            let amount = this.form.amount;
            let currency = this.form.cSend;

            for (let i = 0; i < cs.length; i++) {
                if (cs[i].code === currency) {
                    this.currency_config.decimalLength = cs[i].decimals;
                }
            }

            for (let i = 0; i < mins.length; i++) {
                if (mins[i].data.currency === currency) {
                    this.min = parseInt(mins[i].data.value);
                }
            }

            for (let i = 0; i < maxs.length; i++) {
                if (maxs[i].data.currency === currency) {
                    this.max = parseInt(maxs[i].data.value);
                }
            }

            if (amount !== null && amount > 0) {
                for (let i = 0; i < fees.length; i++) {
                    if (fees[i].category === 'In') {
                        if (amount >= fees[i].range_min && amount < fees[i].range_max) {
                            if (fees[i].currency === currency || (!fee && fees[i].currency === null)) {
                                if (fees[i].type === '%') {
                                    fee = parseFloat(amount * (fees[i].value / 100)).toFixed(this.currency_config.decimalLength);
                                } else {
                                    fee = parseFloat(fees[i].value);
                                }
                            }
                        }
                    }
                }
                this.form.feeIn = fee;
                this.form.vSend = amount - fee;
                this.calculator();
            }
        },
        calculator() {
            let quotes = [];
            let cr = this.cr;
            let fees = this.fees;
            let banks = this.banks;
            let rates = this.rates;
            let amount = this.form.vSend;
            let currency = this.form.cSend;

            for (let i = 0; i < rates.length; i++) {
                let fee = 0;
                let result = 0;
                if (rates[i].currency_send === currency) {
                    let dd = 2;
                    for (let e = 0; e < cr.length; e++) {
                        if (cr[e].code === rates[i].currency_receiver) {
                            dd = cr[e].decimals;
                        }
                    }

                    let bank = 'Otros';
                    if (rates[i].bank_receiver !== null) {
                        for (let e = 0; e < banks.length; e++) {
                            if (banks[e].code === rates[i].bank_receiver) {
                                bank = banks[e].name;
                            }
                        }
                    }

                    if (rates[i].type === 'sell') {
                        result = parseFloat(amount / rates[i].value).toFixed(dd);
                    } else if (rates[i].type === 'buy') {
                        result = parseFloat(amount * rates[i].value).toFixed(dd);
                    }

                    for (let e = 0; e < fees.length; e++) {
                        if (fees[e].category === 'Out') {
                            if (result >= fees[e].range_min && result < fees[e].range_max) {
                                if (fees[e].currency === rates[i].currency_receiver || (!fee && fees[e].currency === null)) {
                                    if (fees[e].type === '%') {
                                        fee = parseFloat(result * (fees[e].value / 100)).toFixed(dd);
                                    } else {
                                        fee = fees[e].value;
                                    }
                                }
                            }
                        }
                    }

                    quotes.push({
                        "value": result - fee,
                        "currency": rates[i].currency_receiver,
                        "fee": fee,
                        "fx": rates[i].value,
                        "bank": bank
                    });
                }
            }

            if (quotes.length > 0) {
                this.quotes = quotes;
                this.form.fx = quotes[0].fx;
                this.form.feeOut = quotes[0].fee;
                this.form.vReceiver = quotes[0].value;
                this.form.cReceiver = quotes[0].currency;
                this.form.quote = quotes[0];
            }
        },
        getConvert(i) {
            this.form.fx = i.fx;
            this.form.vReceiver = i.value;
            this.form.cReceiver = i.currency;
            this.form.quote = i;
        }
    },
    destroyed() {
        clearTimeout(this.timeout);
    }
}
</script>

<style scoped>

</style>

