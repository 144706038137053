<template>
    <v-container>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block py-sm-2 text-center font-weight-bold primary warning--text"
                                  style="word-break: normal">MI HISTORIAL
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getHistory(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-tabs v-model="tab" grow background-color="primary" dark>
                    <v-tabs-slider color="warning"></v-tabs-slider>
                    <v-tab><v-icon left>mdi-hand-coin</v-icon> Pagos</v-tab>
                    <v-tab><v-icon left>mdi-cash</v-icon> Giros</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card>
                            <v-card-title>
                                <v-text-field v-model="SearchP" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                            </v-card-title>
                            <v-data-table :headers="HeadersP" :items="Payments" :search="SearchP" :loading="loading"
                                          :no-data-text="noDataText" :footer-props="footerProps" dense>
                                <template v-slot:item.id="{ item }">
                                    <strong>{{ item.id }}</strong>
                                </template>
                                <template v-slot:item.status="{ item }">
                                    <v-chip small label :color="getColor(item.status_id)" dark>
                                      <strong>{{ item.status }}</strong>
                                    </v-chip>
                                </template>
                                <template v-slot:item.action="{ item }">
                                    <v-btn color="info" small depressed class="ma-1" :disabled="!item.voucher" title="Ver" @click="getVoucher(item.id, 'P')">
                                        <v-icon>mdi-image-area</v-icon>
                                    </v-btn>
                                    <v-btn color="primary" small depressed class="ma-1" title="Detalles" :to="'/history/payment/' + item.id">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card>
                            <v-card-title>
                                <v-text-field v-model="SearchT" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                            </v-card-title>
                            <v-data-table :headers="HeadersT" :items="Transfers" :search="SearchT" :expanded.sync="expanded"
                                        :loading="loading" :no-data-text="noDataText" :expand-icon="expandIcon"
                                        :footer-props="footerProps" single-expand show-expand dense>
                                <template v-slot:item.id="{ item }">
                                    <strong>{{item.id}}</strong>
                                </template>
                                <template v-slot:item.name="{ item }">
                                    <span style="text-transform: capitalize">{{ item.name ? (item.name).toLowerCase() : '' }}</span>
                                </template>
                                <template v-slot:item.status="{ item }">
                                    <v-chip small label :color="getColor(item.status_id)" dark>
                                        <strong>{{ item.status }}</strong>
                                    </v-chip>
                                </template>
                                <template v-slot:item.action="{ item }">
                                    <v-btn color="info" small depressed class="ma-1" :disabled="!item.voucher" title="Ver" @click="getVoucher(item.id, 'T')">
                                        <v-icon>mdi-image-area</v-icon>
                                    </v-btn>
                                    <v-btn color="primary" small depressed class="ma-1" title="Detalles" :to="'/history/transfer/' + item.id">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length" class="py-4">
                                        <v-row>
                                            <v-col cols="12" md="6" class="py-2">
                                                <div class="text-center">
                                                    <strong>Medio de Pago: </strong>{{ item.bank_name }}
                                                </div>
                                            </v-col>
                                            <v-col cols="12" md="6" class="py-2">
                                                <div class="text-center" v-if="item.country_receiver === 'VEN'">
                                                    <strong>Cuenta {{ (item.tc === 'Ahorros' ? 'de ' : '') + item.tc }}:</strong> {{ (item.bank_code + item.nc).match(/.{1,4}/g).join(' ') }}
                                                </div>
                                                <div class="text-center" v-else-if="item.nc === null">
                                                    <strong>Documento:</strong> {{ item.td + ' ' + item.nd }}
                                                </div>
                                                <div class="text-center" v-else>
                                                    <strong>Cuenta {{ (item.tc === 'Ahorros' ? 'de ' : '') + item.tc }}:</strong> {{ item.nc }}
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6" class="py-2">
                                                <div class="text-center">
                                                    <strong>Fecha de Creación: </strong>{{ item.created_at }}
                                                </div>
                                            </v-col>
                                            <v-col cols="12" md="6" class="py-2">
                                                <div class="text-center">
                                                    <strong>Última Actualización: </strong>{{ item.updated_at }}
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.status_id === 253">
                                            <v-col cols="12" class="text-center py-2">
                                                <v-btn color="tertiary" dark to="/corrections">
                                                    <v-icon>mdi-alert</v-icon>&nbsp;&nbsp;Corregir
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </td>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
                <v-dialog v-model="view" scrollable max-width="1000" width="80%">
                    <v-card>
                        <v-card-title class="text-center headline primary warning--text d-inline-block"
                            style="word-break: normal; width: 100%">Comprobante # {{voucherId}}</v-card-title>
                        <v-card-text>
                            <img :src="'/'+voucher" :alt="voucherId" width="100%" />
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn color="error" @click="view = false"><v-icon>mdi-close-circle</v-icon> Cerrar</v-btn>
                            <v-spacer/>
                            <a class="v-btn theme--light v-size--default primary"
                               :href="'/'+voucher" :download="'Voucher_' + title + '_[' + voucherId + '].jpg'">
                                Descargar <v-icon dark>mdi-download</v-icon>
                            </a>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "History",
        props: [ 'user' ],
        data: () => ({
            SearchP: '',
            HeadersP: [
                { text: 'ID', value: 'id', align: 'right' },
                { text: 'Valor', value: 'amount', align: 'right', sortable: false },
                { text: 'Moneda', value: 'currency', align: 'left', sortable: false },
                { text: 'Países', value: 'countries', align: 'left', sortable: false },
                { text: 'Creación', value: 'created_at', align: 'right', sortable: false },
                { text: 'Aprobación', value: 'updated_at', align: 'right', sortable: false },
                { text: 'Estado', value: 'status', align: 'center', sortable: false },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            Payments: [],
            SearchT: '',
            HeadersT: [
                { text: 'ID', value: 'id', align: 'right' },
                { text: 'Beneficiario', value: 'name', align: 'right', sortable: false },
                { text: 'Enviado', value: 'amount_send', align: 'right', sortable: false },
                { text: 'Pagado', value: 'amount_receiver', align: 'right', sortable: false },
                { text: 'Tasa', value: 'fx', align: 'right', sortable: false },
                { text: 'País', value: 'countries', align: 'center', sortable: false },
                { text: 'Estado', value: 'status', align: 'center', sortable: false },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false },
                { text: '', value: 'data-table-expand', filterable: false }
            ],
            Transfers: [],
            expanded: [],
            loading: true,
            noDataText: 'No has realizado solicitudes',
            expandIcon: 'mdi-chevron-down-circle',
            footerProps: {
                itemsPerPageOptions: [25, 50, 100, 250, -1]
            },
            title: null,
            voucherId: null,
            voucher: null,
            view: false,
            tab: 0
        }),
        created() {
            if (this.$route.query.tab === 'transfers') {
                this.tab = 1;
            }
        },
        mounted() {
            this.getHistory();
        },
        methods: {
            getHistory(v) {
                clearTimeout(this.timeout);
                this.loading = true;

                if (v === 1) {
                    this.Payments = [];
                    this.Transfers = [];
                }

                axios.get('/api/history').then((res) => {
                    this.Payments = res.data.payments;
                    this.Transfers = res.data.transfers;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
                this.timeout = setTimeout(this.getHistory, 70000);
            },
            getColor(status) {
                if (status === 253) return 'tertiary';
                else if (status === 203 || status === 254) return 'success';
                else if (status === 201 || status === 251) return 'accent';
                else if (status === 204 || status === 205 || status === 206 || status === 255) return 'error';
                else return 'info';
            },
            getVoucher(id, k) {
                let items = this.Payments;
                if (k === 'T') {
                    items = this.Transfers;
                }

                for (let i = 0; i < items.length; i++) {
                    if (items[i].id === id) {
                        this.voucher = items[i].voucher;
                    }
                }
                this.title = k === 'T' ? 'Transfer' : 'Payment';
                this.voucherId = id;
                this.view = true;
            },
            getDownload() {
                axios({
                    url: '/' + this.voucher,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    let fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'Voucher.jpg');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                });
            }
        },
        destroyed() {
            clearTimeout(this.timeout);
        }
    }
</script>

<style scoped>

</style>
