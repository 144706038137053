<template>
    <v-container fluid class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">REPORTE COMPLETADAS
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getReport(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-text class="pb-0">
                        <v-row justify="center">
                            <v-col cols="12" sm="6" md="4">
                                <v-dialog
                                    ref="menu1"
                                    v-model="menu.start"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :label="labels.start"
                                            v-model="start"
                                            name="start"
                                            prepend-icon="mdi-calendar"
                                            :error-messages="errors.start"
                                            :rules="[rules.req('start')]"
                                            :disabled="loading"
                                            @input="clearErrors('start')"
                                            hide-details
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="form.start"
                                        scrollable
                                        :max="maxDate"
                                        :min="minDate"
                                        @change="menu.start = false"
                                    ></v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-dialog
                                    ref="menu2"
                                    v-model="menu.end"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :label="labels.end"
                                            v-model="end"
                                            name="end"
                                            prepend-icon="mdi-calendar"
                                            :error-messages="errors.end"
                                            :rules="[rules.req('end')]"
                                            :disabled="loading"
                                            @input="clearErrors('end')"
                                            hide-details
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="form.end"
                                        scrollable
                                        :max="maxDate"
                                        :min="minDate"
                                        @change="menu.end = false"
                                    ></v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" sm="6" md="2">
                                <v-btn color="primary" block large depressed @click="getReport">
                                    <v-icon>mdi-filter</v-icon>&nbsp;&nbsp;Filtrar
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="6" md="2">
                                <v-btn color="success" block large depressed @click="getDownload">
                                    <v-icon>mdi-file-excel</v-icon>&nbsp;&nbsp;Descargar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-title class="pt-0">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item. id }}</strong>
                        </template>
                        <template v-slot:item.sender="{ item }">
                            <span style="text-transform: capitalize">{{ (item.sender).toLowerCase() }}</span>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                        </template>
                        <template v-slot:item.voucher="{ item }">
                            <v-btn color="primary" small dark depressed @click="getVoucher(item.id, item.voucherP)"
                                   style="margin: 1px" v-if="item.voucherP">
                                <v-icon>mdi-panorama</v-icon> P
                            </v-btn>
                            <v-btn color="grey" small depressed disabled style="margin: 1px" v-else>
                                <v-icon>mdi-panorama</v-icon> P
                            </v-btn>
                            <v-btn color="primary" small dark depressed @click="getVoucher(item.id, item.voucher)"
                                   style="margin: 1px" v-if="item.voucher">
                                <v-icon>mdi-panorama</v-icon> T
                            </v-btn>
                            <v-btn color="grey" small depressed disabled style="margin: 1px" v-else>
                                <v-icon>mdi-panorama</v-icon> T
                            </v-btn>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="primary" small depressed :to="'/history/transfer/' + item.id">
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="view" scrollable max-width="1000px" width="80%" v-if="voucher">
                    <v-card>
                        <v-card-title class="text-center headline primary warning--text d-inline-block"
                                      style="color: #ffffff; word-break: normal; width: 100%">Comprobante # {{ voucherId }}</v-card-title>
                        <v-card-text class="pa-6">
                            <v-skeleton-loader class="mx-auto" width="100%" type="image" v-if="!voucher"/>
                            <img :src="'/' + voucher" :alt="voucherId" width="100%" v-else/>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn color="error" @click="closeVoucher"><v-icon>mdi-close-circle</v-icon> Cerrar</v-btn>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../../../helpers/form";

    export default {
        name: "Completed",
        mixins: [ Form ],
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'ID', value: 'id', sortable: false },
                { text: 'Enviador', value: 'sender', sortable: false },
                { text: 'Receptor', value: 'name', sortable: false },
                { text: 'Recibido', value: 'amount_send_text', align: 'end', sortable: false },
                { text: 'Tasa', value: 'fx_rate', align: 'center', sortable: false },
                { text: 'Pagado', value: 'amount_receiver_text', align: 'end', sortable: false },
                { text: 'Paises', value: 'countries', align: 'center', sortable: false },
                { text: 'Fecha Inicial', value: 'created_at', sortable: false },
                { text: 'Fecha Final', value: 'updated_at', sortable: false },
                { text: 'Vouchers', value: 'voucher', align: 'center', filterable: false, sortable: false },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: true,
            noDataText: 'No tienes transacciones completadas',
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            voucherId: null,
            voucher: null,
            view: false,
            menu: {
                start: false,
                end: false
            },
            labels: {
                start: 'Fecha Inicial',
                end: 'Fecha Final'
            },
            form: {
                start: undefined,
                end: undefined
            },
            minDate: undefined,
            maxDate: undefined
        }),
        mounted () {
            this.getDate();
            this.getReport();
        },
        computed: {
            start() {
                return this.formatDate(this.form.start)
            },
            end() {
                return this.formatDate(this.form.end)
            },
        },
        methods: {
            getReport(v) {
                clearTimeout(this.timeout);
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.post('/api/reports/completed', this.form).then((res) => {
                    this.desserts = res.data.transfers;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
                this.timeout = setTimeout(this.getReport, 120000);
            },
            getDate() {
                const now = new Date()
                const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
                today.setDate(today.getDate())
                // 5 años menos
                const minDate = new Date(today)
                minDate.setFullYear(minDate.getFullYear() - 5)
                // 1 mes menos
                const start = new Date(today)
                start.setMonth(start.getMonth() - 1)

                this.minDate = minDate.toISOString().slice(0, 10);
                this.maxDate = today.toISOString().slice(0, 10);
                this.form.start = start.toISOString().slice(0, 10);
                this.form.end = today.toISOString().slice(0, 10);
            },
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}/${month}/${year}`
            },
            getVoucher(id, voucher) {
                this.voucher = voucher;
                this.voucherId = id;
                this.view = true;
            },
            closeVoucher() {
                this.view = false;
                this.voucher = null;
                this.voucherId = null;
            },
            getDownload() {
                let url = '/api/export/reports/completed/' + this.form.start + '/' + this.form.end;
                axios.get(url, { responseType: 'blob' }).then((res) => {
                    const blob = new Blob([res.data], {type: res.data.type});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    const filename = res.headers['content-disposition'].split('filename=')[1];
                    const name = 'Reporte Completadas ' + filename.replace(/"/gi, '');
                    link.setAttribute('download', name);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                });
            }
        }
    }
</script>

<style scoped>

</style>
