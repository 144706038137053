<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">REPORTE OFICINAS
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getReport(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-subtitle class="d-block text-center font-weight-bold bg primary--text py-2" style="word-break: normal">
                        <u>BALANCE POR OFICINA</u>
                    </v-card-subtitle>
                    <v-card-title class="pt-0">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.amount_text="{ item }">
                            <v-chip class="error--text" v-if="item.amount < 0">
                                <b>{{ item.amount_text }}</b>
                            </v-chip>
                            <v-chip class="success--text" v-else>
                                <b>{{ item.amount_text }}</b>
                            </v-chip>
                        </template>
                        <template v-slot:item.currency="{ item }">
                            <b>{{ item.currency }}</b>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="success" small depressed dark @click="getDownload(item.code, item.currency)">
                                <v-icon>mdi-file-excel</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-subtitle class="d-block text-center font-weight-bold bg primary--text py-2" style="word-break: normal">
                        <v-row justify="center">
                            <v-col cols="12">
                                <u>ULTIMOS MOVIMIENTOS</u>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" class="py-0">
                                <v-select
                                    :items="offices"
                                    v-model="office"
                                    item-text="name"
                                    item-value="id"
                                    class="mt-0 pt-0"
                                    @change="getMovs(1)"
                                    hide-details/>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                    <v-card-text class="pa-0">
                        <v-tabs grow>
                            <template v-for="(i, k) in items">
                                <v-tab @click="search = ''">
                                    <b>{{ k }}</b>
                                </v-tab>
                                <v-tab-item>
                                    <v-card>
                                        <v-card-title>
                                            <v-text-field v-model="searchMovs" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                                        </v-card-title>
                                        <v-data-table :headers="headersMovs" :items="i" :search="searchMovs" :loading="loadingMovs"
                                                      :no-data-text="noDataTextMovs" :footer-props="footerProps">
                                            <template v-slot:item.ref="{ item }">
                                                <strong>{{ item.ref }}</strong>
                                            </template>
                                            <template v-slot:item.amount_text="{ item }">
                                                <v-chip class="error--text" v-if="item.type === '-'">
                                                    <b>{{ item.amount_text }}</b>
                                                </v-chip>
                                                <v-chip class="success--text" v-else-if="item.type === '+'">
                                                    <b>{{ item.amount_text }}</b>
                                                </v-chip>
                                            </template>
                                            <template v-slot:item.action="{ item }">
                                                <v-btn color="error" small depressed @click="trash(item.id, item.ref)" :disabled="item.payment_id !== null || item.transfer_id !== null">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-tab-item>
                            </template>
                        </v-tabs>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "reportOffices",
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'PAÍS', value: 'country', sortable: false },
                { text: 'OFICINA', value: 'name', sortable: false },
                { text: 'VALOR', value: 'amount_text', align: 'end', sortable: false },
                { text: 'MONEDA', value: 'currency', align: 'end', sortable: false },
                { text: 'REPORTE', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: true,
            noDataText: 'No tienes oficinas registradas',
            searchMovs: '',
            headersMovs: [
                { text: 'FECHA', value: 'created_at', sortable: false },
                { text: 'CÓDIGO', value: 'ref', sortable: false },
                { text: 'DETALLES', value: 'details', sortable: false },
                { text: 'VALOR', value: 'amount_text', align: 'end', sortable: false },
                { text: 'MONEDA', value: 'currency', align: 'end', sortable: false },
                { text: 'ACCION', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            items: [],
            loadingMovs: true,
            noDataTextMovs: 'No tienes movimientos registrados',
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            office: 1,
            offices: []
        }),
        created () {
            this.getReport();
        },
        methods: {
            getReport(v) {
                clearTimeout(this.timeout);
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.get('/api/reports/offices').then((res) => {
                    this.desserts = res.data.items;
                    this.offices = res.data.offices;
                    this.getMovs(v);
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
                this.timeout = setTimeout(this.getReport, 120000);
            },
            getMovs(v) {
                this.loadingMovs = true;
                if (v === 1) { this.items = []; }
                let formData = new FormData();
                formData.append('id', this.office);
                axios.post('/api/reports/offices/movements', formData).then((res) => {
                    this.items = res.data.items;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loadingMovs = false;
                });
            },
            getDownload(id, currency) {
                let url = '/api/export/reports/offices/' + id + '/' + currency;

                axios.get(url, {
                    responseType: 'blob'
                }).then((res) => {
                    const blob = new Blob([res.data], {type: res.data.type});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    const filename = res.headers['content-disposition'].split('filename=')[1];
                    const name = 'Reporte ' + filename.replace(/"/gi, '');
                    link.setAttribute('download', name);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                });
            },
            trash(id, ref) {
                this.$swal({
                    title: '¿Esta seguro de eliminar el movimiento ' + ref + '?',
                    type: 'warning',
                    confirmButtonText: 'Si, eliminar',
                    cancelButtonText: 'No, cancelar'
                }).then((result) => {
                    if (result.value) {
                        let formData = new FormData();
                        formData.append('id',  id);
                        axios.post('/api/reports/offices/movements/delete', formData).then((res) => {
                            if (res.data.v === 'success') {
                                this.$swal({
                                    title: '¡Felicitaciones!',
                                    text: 'El movimiento #' + ref + ' se ha eliminado exitosamente',
                                    type: 'success',
                                    showConfirmButton: false,
                                    timer: 5000
                                });
                                this.$toast.success(res.data.status, { icon: 'mdi-check-circle' });
                                this.getReport();
                            } else if (res.data.v === 'warning') {
                                this.$toast.warning(res.data.status, {icon: 'mdi-information'});
                            } else {
                                this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                            }
                        }).catch(err => {
                            this.handleErrors(err.response.data.errors);
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
