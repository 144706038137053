<template>
    <div class="fill-height">
        <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" dark :class="(user.role === 'Customer' ? 'px-2' : 'pr-2')">
            <v-menu transition="scroll-y-transition" bottom right offset-y v-if="user.role === 'Customer'">
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon x-large>mdi-account-circle</v-icon>
                    </v-btn>
                </template>
                <v-list class="list-custom">
                    <v-list-item to="/profile/edit">
                        <v-icon>mdi-account-edit</v-icon>
                        <v-list-item-title class="pl-2">Editar Perfil</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/profile/password">
                        <v-icon>mdi-account-key</v-icon>
                        <v-list-item-title class="pl-2">Cambiar Clave</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout">
                        <v-icon color="error">mdi-close-circle</v-icon>
                        <v-list-item-title class="pl-2 error--text">Cerrar Sesión</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-else/>
            <v-spacer/>
            <v-toolbar-title>
                <v-img class="mx-3" src="/img/logo-icoin-app.png" max-height="50px" contain></v-img>
            </v-toolbar-title>
            <v-spacer/>
            <v-btn icon class="mr-2" v-if="user.role === 'Customer'" :to="{ name: 'Claims' }">
                <v-badge offset-x="8" offset-y="10" overlap color="error">
                    <template v-slot:badge>{{ cc }}</template>
                    <v-icon large>mdi-chat</v-icon>
                </v-badge>
            </v-btn>
            <v-btn icon class="mr-2" v-if="user.role === 'Support'"
                   v-show="$vuetify.breakpoint.smAndUp" :to="{ name: 'CPending' }">
                <v-badge offset-x="8" offset-y="10" overlap color="error">
                    <template v-slot:badge>{{ cc }}</template>
                    <v-icon large>mdi-chat</v-icon>
                </v-badge>
            </v-btn>
            <v-btn icon class="mr-2" v-if="user.role === 'Checker' || user.role === 'Administrator'"
                   v-show="$vuetify.breakpoint.smAndUp" :to="{ name: 'VPending' }">
                <v-badge offset-x="8" offset-y="10" overlap color="error">
                    <template v-slot:badge>{{ cp }}</template>
                    <v-icon large>mdi-alpha-v-circle</v-icon>
                </v-badge>
            </v-btn>
            <v-btn icon class="mr-2" v-if="user.role === 'Payer' || user.role === 'Administrator'"
                   v-show="$vuetify.breakpoint.smAndUp" :to="{ name: 'TPending' }">
                <v-badge offset-x="8" offset-y="10" overlap color="error">
                    <template v-slot:badge>{{ ct }}</template>
                    <v-icon large>mdi-alpha-t-circle</v-icon>
                </v-badge>
            </v-btn>
            <v-menu transition="scroll-y-transition" bottom left offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-avatar size="36">
                            <img :src="'/img/country/' + user.country + '.png'" :alt="user.country === null ? '' : user.country.toLowerCase()">
                        </v-avatar>
                    </v-btn>
                </template>
                <v-list class="list-custom">
                    <v-list-item v-for="(i, v) in countries" :key="v" @click="country(i.code)" v-if="i.code !== user.country">
                        <v-list-item-avatar>
                            <img :src="'/img/country/'+i.code+'.png'" :title="i.name" :alt="i.name.toLowerCase()" />
                        </v-list-item-avatar>
                        <v-list-item-title>{{ i.name }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" v-if="user.role !== 'Customer'" :clipped="$vuetify.breakpoint.lgAndUp" app>
            <template v-slot:prepend>
                <v-list class="pa-0">
                    <v-list-item>
                        <v-list-item-avatar size="80" style="border: 1px solid #ccc;">
                            <img :src="'/' + (user.photo ? user.photo : (user.role === 'Customer' ? 'img/photo-default.png' : 'img/soporte.jpg' ))" :alt="user.name">
                        </v-list-item-avatar>
                    </v-list-item>
                    <v-list-group prepend-icon="" append-icon="">
                        <template v-slot:activator>
                            <v-list-item link style="border-bottom: 1px solid #ccc; margin: 0 -15px">
                                <v-list-item-content class="py-2">
                                    <v-list-item-title>
                                        <strong>{{ user.name }}</strong>
                                    </v-list-item-title>
                                    <v-list-item-content class="success--text d-inline-block py-0" v-if="user.status === 103">Verificado
                                        <v-icon small color="success">mdi-check-circle</v-icon>
                                    </v-list-item-content>
                                    <v-list-item-content class="info--text d-inline-block py-0" v-else-if="user.status === 102">En Revisión
                                        <v-icon small color="primary">mdi-sync-circle</v-icon>
                                    </v-list-item-content>
                                    <v-list-item-content class="error--text d-inline-block py-0" v-else-if="user.status === 104">Bloqueado
                                        <v-icon small color="error">mdi-close-circle</v-icon>
                                    </v-list-item-content>
                                    <v-list-item-content class="tertiary--text d-inline-block py-0" v-else>Sin Verificar
                                        <v-icon small color="secondary">mdi-alert-circle</v-icon>
                                    </v-list-item-content>
                                    <v-list-item-subtitle>
                                        <strong>ID: {{ !user.id ? '' : (user.id).toString().padStart(5, 0) }}</strong>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <v-list-item dense class="pl-6" to="/profile/edit">
                            <v-list-item-action>
                                <v-icon>mdi-account-edit</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Editar Perfil</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense class="pl-6" to="/profile/password">
                            <v-list-item-action>
                                <v-icon>mdi-account-key</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Cambiar Contraseña</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </template>
            <v-divider/>
            <v-list dense class="pt-0">
                <template v-for="item in items">
                    <v-subheader v-if="item.heading" class="pl-5 grey lighten-5 grey--text text--darken-4 font-weight-black">
                        {{item.heading}}
                    </v-subheader>
                    <v-list-group v-else-if="item.children" v-model="item.model" :prepend-icon="item.icon" append-icon="mdi-menu-down">
                        <template v-slot:activator>
                            <v-list-item class="px-0">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-for="(child, i) in item.children">
                            <v-list-group v-if="child.children" sub-group :key="i" v-model="child.model" :prepend-icon="child.model ? 'mdi-arrow-down-drop-circle' : child.icon">
                                <template v-slot:activator>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ child.text }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <v-list-item v-for="(child2, i) in child.children" :key="i" :to="'/' + item.link + '/' + child.link + '/' + child2.link">
                                    <v-list-item-action v-if="child2.icon">
                                        <v-icon>{{ child2.icon }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ child2.text }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                            <v-list-item class="pl-6" :key="i" :to="'/' + item.link + '/' + child.link" v-else>
                                <v-list-item-action v-if="child.icon">
                                    <v-icon>{{ child.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ child.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list-group>
                    <v-list-item v-else-if="item.link === 'logout'" @click="logout()">
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-else :to="'/' + item.link">
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>
        <v-main :class="(user.role === 'Customer' ? 'mb-14': '')">
            <v-banner single-line v-if="user.role === 'Administrator' && $route.name === 'Home'">
                <v-row justify="space-around" class="mr-0">
                    <v-switch v-model="t" inset class="ma-2 pt-0" color="primary" label="Abrir Cuenta" hide-details :disabled="c === true"/>
                    <v-switch v-model="c" inset class="ma-2 pt-0" color="primary" label="Abrir Caja" hide-details :disabled="t === true"/>
                </v-row>
            </v-banner>
            <v-banner v-if="c === true || user.role === 'Cashier'">
                <v-icon slot="icon" color="primary" size="32">mdi-cash-register</v-icon>
                <span v-if="officeName === null && show === false">No tienes ninguna oficina selecionada</span>
                <span v-else-if="officeName !== null && show === false">Caja Activa: <strong>{{ officeName }}</strong></span>
                <v-skeleton-loader min-width="300px" type="list-item" v-else/>
                <template v-slot:actions>
                    <v-btn outlined color="primary" @click="openCash = true" v-if="officeName === null && show === false">
                        <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;Abrir
                    </v-btn>
                    <v-btn outlined color="error" @click="closeOffice" v-else-if="officeName !== null && show === false">
                        <v-icon>mdi-close-circle</v-icon>&nbsp;&nbsp;Cerrar
                    </v-btn>
                    <v-skeleton-loader type="button" min-width="100px" style="" v-else/>
                </template>
                <v-dialog v-model="openCash" scrollable max-width="800px">
                    <v-card>
                        <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                        <v-form ref="form" @submit.prevent="openOffice" lazy-validation v-model="valid">
                            <v-card-title class="text-center headline primary warning--text d-inline-block"
                                          style="color: #ffffff; word-break: normal; width: 100%">Abrir Caja Oficina</v-card-title>
                            <v-card-text>
                                <v-row justify="center">
                                    <v-col cols="12" sm="10" md="8" lg="6">
                                        <v-autocomplete
                                            :label="labels.office"
                                            :items="offices"
                                            name="account"
                                            v-model="form.office"
                                            :error-messages="errors.office"
                                            :disabled="loading"
                                            :rules="[rules.req('office')]"
                                            @select="clearErrors('office')"
                                            prepend-icon="mdi-clipboard-text"
                                            item-text="name"
                                            item-value="id"/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="openCash = false">
                                    <v-icon>mdi-close-circle</v-icon> Cerrar
                                </v-btn>
                                <v-spacer/>
                                <v-btn type="submit" :loading="loading" :disabled="loading || !valid" color="success">
                                    <v-icon>mdi-check-circle</v-icon> Abrir
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-dialog>
            </v-banner>
            <v-banner v-if="t === true || user.role === 'Payer'">
                <v-icon slot="icon" color="primary" size="32">mdi-wallet</v-icon>
                <span v-if="accountName === null && show === false">No tienes ninguna cuenta selecionada</span>
                <span v-else-if="accountName !== null && show === false">Cuenta Activa: <strong>{{ accountName }}</strong><br>Saldo: <strong>{{ balance }}</strong></span>
                <v-skeleton-loader min-width="300px" type="list-item" v-else/>
                <template v-slot:actions>
                    <v-btn outlined color="primary" @click="open = true" v-if="accountName === null && show === false">
                        <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;Abrir
                    </v-btn>
                    <v-btn outlined color="error" @click="closeAccount" v-else-if="accountName !== null && show === false">
                        <v-icon>mdi-close-circle</v-icon>&nbsp;&nbsp;Cerrar
                    </v-btn>
                    <v-skeleton-loader type="button" min-width="100px" style="" v-else/>
                </template>
                <v-dialog v-model="open" scrollable max-width="800px">
                    <v-card>
                        <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                        <v-form ref="form" @submit.prevent="openAccount" lazy-validation v-model="valid">
                            <v-card-title class="text-center headline primary warning--text d-inline-block" style="color: #ffffff; word-break: normal; width: 100%">Abrir Cuenta Bancaria</v-card-title>
                            <v-card-text>
                                <v-row justify="center">
                                    <v-col cols="12" sm="10" md="8" lg="6">
                                        <v-autocomplete
                                            :label="labels.account"
                                            :items="accounts"
                                            name="account"
                                            v-model="form.account"
                                            :error-messages="errors.account"
                                            :disabled="loading"
                                            :rules="[rules.req('account')]"
                                            @select="clearErrors('account')"
                                            prepend-icon="mdi-clipboard-text"
                                            item-text="text"
                                            item-value="id">
                                            <template v-slot:selection="{ item }">
                                                <v-flex class='ml-1'><b>{{ item.code }}</b> - {{ (item.alias + ' - ' + item.currency).toUpperCase() }}</v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="'<b>' + item.code + '</b> - ' + (item.alias + ' - ' + item.currency).toUpperCase()"/>
                                                    <v-list-item-subtitle v-html="'<b>' + item.bank_name + '</b>'"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="open = false">
                                    <v-icon>mdi-close-circle</v-icon> Cerrar
                                </v-btn>
                                <v-spacer/>
                                <v-btn type="submit" :loading="loading" :disabled="loading || !valid" color="success">
                                    <v-icon>mdi-check-circle</v-icon> Abrir
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-dialog>
            </v-banner>
            <transition name="fade" mode="out-in">
                <router-view :user="user" :office="office" :account="accountName" @success="update"/>
            </transition>
        </v-main>
        <v-bottom-navigation grow fixed background-color="primary" color="amber lighten-1" dark v-show="user.role === 'Customer'">
            <v-btn class="px-1" to="/">
                <span>Inicio</span>
                <v-icon>mdi-apps</v-icon>
            </v-btn>
            <v-btn class="px-1" to="/calculator">
                <span>Cotizador</span>
                <v-icon>mdi-calculator-variant</v-icon>
            </v-btn>
            <v-btn class="px-1" to="/request">
                <span>Enviar</span>
                <v-icon>mdi-cash-multiple</v-icon>
            </v-btn>
            <v-btn class="px-1" to="/history">
                <span>Historial</span>
                <v-icon>mdi-list-status</v-icon>
            </v-btn>
            <v-btn class="px-1" to="/accounts">
                <span>Contactos</span>
                <v-icon>mdi-account-group</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import axios from 'axios';
    import Form from '../helpers/form';
    import { getUA, isIOS, isAndroid } from 'mobile-device-detect';

    export default {
        name: "AdminComponent",
        mixins: [ Form ],
        data: () => ({
            loading: true,
            useragent: getUA,
            iOS: isIOS,
            Android: isAndroid,
            drawer: null,
            countries: [
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Mexico', code: 'MEX' },
                { name: 'Panama', code: 'PAN' },
                { name: 'Peru', code: 'PER' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'Venezuela', code: 'VEN' }
            ],
            user: {
                id: null,
                photo: null,
                name: null,
                country: null,
                role: 'Customer',
                office_id: null,
                account_id: null,
                status: null,
                suspendReason: null
            },
            cc: '0',
            cp: '0',
            ct: '0',
            show: true,
            accounts: [],
            offices: [],
            open: false,
            openCash: false,
            labels: {
                account: 'Cuenta Bancaria',
                office: 'Oficina'
            },
            form: {
                account: null,
                office: null
            },
            accountName: null,
            officeName: null,
            office: {
                name: null,
                address: null,
                city: null,
                country: null
            },
            balance: '',
            c: false,
            t: false
        }),
        mounted() {
            this.user = Object.assign(this.user, this.auth);
            this.countChecker();
            this.getAccounts();
            this.getOffices();
            this.getBalance();
            this.c = this.user.office_id > 0;
            this.t = this.user.account_id > 0;
        },
        watch: {
            user() {
                this.getAccounts();
                this.getOffices();
                this.getBalance();
            }
        },
        computed: {
            ...mapGetters({
                auth: 'user'
            }),
            items() {
                const role = this.user.role;
                const calculator = { icon: 'mdi-calculator', text: 'Calculadora', link: 'calculator' };
                let menu = [
                    { icon: 'mdi-view-dashboard', text: 'Inicio', link: '' }
                ];

                if (role === 'Administrator') {
                    menu.push(
                        calculator,
                        {
                            icon: 'mdi-cash-register', text: 'Cajero', link: 'cashier', children: [
                                { icon: 'mdi-cash-100', text: 'Cobrar', link: 'in' },
                                { icon: 'mdi-cash-remove', text: 'Pagar', link: 'out' },
                                { icon: 'mdi-cash-register', text: 'Entradas/Salidas', link: 'in-out' },
                                { icon: 'mdi-swap-horizontal-circle', text: 'Cambiar Divisas', link: 'exchange' }
                            ]
                        },{
                            icon: 'mdi-alert-circle-check', text: 'Verificador', link: 'checker', children: [
                                { icon: 'mdi-minus-circle', text: 'Pendientes', link: 'pending' },
                                { icon: 'mdi-check-circle', text: 'Aprobadas', link: 'approved' },
                                { icon: 'mdi-close-circle', text: 'Rechazadas', link: 'rejected' },
                                { icon: 'mdi-alert-circle', text: 'Correcciones', link: 'corrections' }
                            ]
                        },{
                            icon: 'mdi-swap-horizontal-circle', text: 'Pagador', link: 'payer', children: [
                                { icon: 'mdi-minus-circle', text: 'Pendientes', link: 'pending' },
                                { icon: 'mdi-check-circle', text: 'Realizadas', link: 'approved' },
                                { icon: 'mdi-alert-circle', text: 'Correcciones', link: 'corrections' }
                            ]
                        },{
                            icon: 'mdi-forum', text: 'Soporte', link: 'claims', children: [
                                { icon: 'mdi-alert-circle', text: 'Por Responder', link: 'pending' },
                                { icon: 'mdi-check-circle', text: 'Respondidos', link: 'responded' },
                                { icon: 'mdi-close-circle', text: 'Cerrados', link: 'closed' }
                            ]
                        }
                    )
                }
                if (role === 'Support') {
                    menu.push(
                        { heading: 'SOPORTE' },
                        { icon: 'mdi-alert-circle', text: 'Por Responder', link: 'claims/pending' },
                        { icon: 'mdi-check-circle', text: 'Respondidos', link: 'claims/responded' },
                        { icon: 'mdi-close-circle', text: 'Cerrados', link: 'claims/closed' },
                    )
                }
                if (role === 'Administrator' || role === 'Auditor' || role === 'Support') {
                    menu.push(
                        {heading: 'REPORTES'},
                        {icon: 'mdi-file-chart', text: 'Reporte General', link: 'reports/general'},
                        {icon: 'mdi-finance', text: 'Reporte Completadas', link: 'reports/completed'},
                        {icon: 'mdi-office-building', text: 'Reporte Oficinas', link: 'reports/offices'}
                    )
                }
                if (role === 'Administrator' || role === 'Auditor') {
                    menu.push(
                        { heading: 'CONTABILIDAD' },
                        { icon: 'mdi-format-list-text', text: 'Inventario', link: 'movements/accounts' },
                        { icon: 'mdi-contrast-circle', text: 'Traslados', link: 'movements/transfer' },
                        { icon: 'mdi-file-tree', text: 'Entradas/Salidas', link: 'movements/in-out' },
                    )
                }
                if (role === 'Checker' || role === 'Cashier' || role === 'Payer' || role === 'Monitor') {
                    menu.push({ heading: 'TRANSACCIONES' })
                }
                if (role === 'Checker') {
                    menu.push(
                        { icon: 'mdi-minus-circle', text: 'Pendientes', link: 'checker/pending' },
                        { icon: 'mdi-check-circle', text: 'Aprobadas', link: 'checker/approved' },
                        { icon: 'mdi-close-circle', text: 'Rechazadas', link: 'checker/rejected' }
                    )
                }
                if (role === 'Cashier') {
                    menu.push(
                        { icon: 'mdi-cash-100', text: 'Cobrar', link: 'cashier/in' },
                        { icon: 'mdi-cash-remove', text: 'Pagar', link: 'cashier/out' },
                        { icon: 'mdi-cash-register', text: 'Entradas/Salidas', link: 'cashier/in-out' },
                        { icon: 'mdi-swap-horizontal-circle', text: 'Cambiar Divisas', link: 'cashier/exchange' },
                        { heading: 'MOVIMIENTOS' },
                        { icon: 'mdi-scale-balance', text: 'Saldos Actuales', link: 'cashier/balance' },
                        { icon: 'mdi-finance', text: 'Movimientos', link: 'cashier/movements' }
                    )
                }
                if (role === 'Payer') {
                    menu.push(
                        { icon: 'mdi-minus-circle', text: 'Pendientes', link: 'payer/pending' },
                        { icon: 'mdi-check-circle', text: 'Realizadas', link: 'payer/approved' },
                        { icon: 'mdi-contrast-circle', text: 'Traslados', link: 'inventory/transfer' }
                    )
                }
                if (role === 'Monitor') {
                    menu.push(
                        { icon: 'mdi-check-circle', text: 'Aprobadas', link: 'checker/approved' },
                        { heading: 'USUARIOS' },
                        { icon: 'mdi-account-multiple', text: 'Clientes', link: 'users/customers' }
                    )
                }
                if (role === 'Administrator') {
                    menu.push(
                        { heading: 'PANEL DE CONTROL' },
                        {
                            icon: 'mdi-account-group', text: 'Usuarios', link: 'users', children: [
                                { icon: 'mdi-account-multiple', text: 'Clientes', link: 'customers' },
                                { icon: 'mdi-account-tie', text: 'Empleados', link: 'staff' }
                            ]
                        },{
                            icon: 'mdi-cog-transfer', text: 'Ajustes', link: 'settings', children: [
                                { icon: 'mdi-circle-multiple', text: 'Precio', link: 'rate' },
                                { icon: 'mdi-sack-percent', text: 'Comisiones', link: 'fee' },
                                { icon: 'mdi-cash-minus', text: 'Limites', link: 'limits' },
                                { icon: 'mdi-view-headline', text: 'Anuncios', link: 'ads' },
                                { icon: 'mdi-bank', text: 'Medios de Pago', link: 'gateways' },
                                { icon: 'mdi-wallet', text: 'Cuentas', link: 'accounts' },
                                { icon: 'mdi-home-city', text: 'Oficinas', link: 'offices' },
                                { icon: 'mdi-scale-balance', text: 'Legal', link: 'legal' },
                            ]
                        }
                    )
                }
                if (role !== 'Administrator' && role !== 'Customer') {
                    menu.push({ heading: 'UTILIDAD' }, calculator)
                }
                if (role === 'Cashier' || role === 'Support' || role === 'Monitor') {
                    menu.push({ icon: 'mdi-information', text: 'Información', link: 'about' })
                }
                menu.push({ icon: 'mdi-exit-to-app', text: 'Cerrar Sesion', link: 'logout' })

                return menu;
            }
        },
        methods: {
            async logout() {
                await this.$store.dispatch("logout");
                this.$toast.info('Has cerrado sesion correctamente.', { icon: 'mdi-information' });
                await this.$router.push({name: "Login"});
            },
            country(country) {
                axios.post('/api/country', { country: country })
                    .then((res) => {
                        this.user = res.data.user;
                        this.getOffices();
                        this.$store.dispatch('setUser', res.data);
                        this.$toast.success('¡Has actualizado tu país!', { icon: 'mdi-check-circle' });
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    });
            },
            countChecker() {
                clearTimeout(this.timeout);
                axios.get('/api/checker/count').then((res) => {
                    if (res.data.v === 'success') {
                        this.cc = res.data.cc;
                        this.cp = res.data.cp;
                        this.ct = res.data.ct;
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                });
                this.timeout = setTimeout(this.countChecker, 30000);
            },
            getAccounts() {
                let country = this.user.country
                if (this.user.role === 'Administrator' || this.user.role === 'Payer' || this.user.role === 'Support') {
                    axios.get('/api/transfers/t/accounts/' + country).then((res) => {
                        this.accounts = res.data.accounts;
                        if (this.user.account_id > 0) {
                            this.form.account = this.user.account_id;
                            this.getAccountName();
                        } else {
                            this.show = false;
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            },
            openAccount() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();
                    formData.append('id', this.form.account);

                    axios.post('/api/payer/open', formData, {
                        headers: {'Content-Type': 'multipart/form-data'}
                    }).then((res) => {
                        if (res.data.v === 'success') {
                            this.update();
                            this.open = false;
                            this.getAccountName();
                            this.$toast.success(res.data.status, {icon: 'check-circle'});
                        } else if (res.data.v === 'warning') {
                            this.$toast.warning(res.data.status, {icon: 'info-circle'});
                        } else {
                            this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            },
            closeAccount() {
                axios.post('/api/payer/close').then((res) => {
                    if (res.data.v === 'success') {
                        this.update();
                        this.form.account = null;
                        this.accountName = null;
                        this.balance = '$ 0 USD';
                        this.$toast.success(res.data.status, {icon: 'check-circle'});
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                });
            },
            getAccountName() {
                const accounts = this.accounts;

                for (let i = 0; i < accounts.length; i++) {
                    if (accounts[i].id === this.form.account) {
                        this.accountName = accounts[i].code + ' - ' + accounts[i].alias;
                        this.show = false;
                    }
                }
            },
            getOffices() {
                let country = this.user.country
                if (this.user.role === 'Administrator' || this.user.role === 'Cashier') {
                    axios.get('/api/transfers/o/accounts/' + country).then((res) => {
                        this.offices = res.data.accounts;
                        if (this.user.office_id > 0) {
                            this.form.office = this.user.office_id;
                            this.getOfficeName();
                        } else {
                            this.show = false;
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            },
            openOffice() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();
                    formData.append('id', this.form.office);

                    axios.post('/api/cashier/open', formData, {
                        headers: {'Content-Type': 'multipart/form-data'}
                    }).then((res) => {
                        if (res.data.v === 'success') {
                            this.update();
                            this.getOfficeName();
                            this.openCash = false;
                            this.$toast.success(res.data.status, {icon: 'check-circle'});
                        } else {
                            this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    });
                }
            },
            closeOffice() {
                axios.post('/api/cashier/close').then((res) => {
                    if (res.data.v === 'success') {
                        this.update();
                        this.officeName = null;
                        this.office = {
                            name: null,
                            address: null,
                            city: null,
                            country: null
                        };
                        this.form.office = null;
                        this.$toast.success(res.data.status, {icon: 'check-circle'});
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                });
            },
            getOfficeName() {
                const offices = this.offices;

                for (let i = 0; i < offices.length; i++) {
                    if (offices[i].id === this.form.office) {
                        this.officeName = offices[i].name;
                        this.office = {
                            name: offices[i].name,
                            address: offices[i].address,
                            city: offices[i].city,
                            country: offices[i].country
                        };
                        this.show = false;
                    }
                }
            },
            getBalance() {
                clearTimeout(this.countBalance);
                if ((this.user.role === 'Administrator' || this.user.role === 'Payer' || this.user.role === 'Support') && this.user.account_id !== 0) {
                    axios.get('/api/balance').then((res) => {
                        if (res.data.v === 'success') {
                            this.balance = res.data.balance;
                        } else {
                            this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    });
                }
                this.countBalance = setTimeout(this.getBalance, 30000);
            },
            update() {
                axios.get('/api/profile').then((res) => {
                    this.user = res.data.user;
                    this.$store.dispatch('setUser', res.data);
                    if (this.user.status !== 103) {
                        this.timeout = setTimeout(this.update, 300000);
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                });
            }
        },
        destroyed() {
            clearTimeout(this.timeout);
            clearTimeout(this.countBalance);
        }
    }
</script>

<style scoped>
.list-custom{
    min-height: 48px;
    max-height: 448px;
    overflow-y: auto;
    padding: 0;
}
.v-item-group.v-bottom-navigation .v-btn {
    min-width: 64px;
}
</style>
