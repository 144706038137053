<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">CONFIGURAR LIMITES
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getData(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card>
                    <v-row justify="center" class="mx-0">
                        <v-col cols="12">
                            <v-btn color="primary" large block depressed @click="get()" style="margin: 1px">
                                <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;Agregar Limite</v-btn>
                        </v-col>
                    </v-row>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.id="{ item }">
                            <b>{{ item.limitId }}</b>
                        </template>
                        <template v-slot:item.currency="{ item }">
                            {{ item.currency ? item.currency : 'Todas' }}
                        </template>
                        <template v-slot:item.country_text="{ item }">
                            {{ item.country_text ? item.country_text : 'Todos' }}
                        </template>
                        <template v-slot:item.destiny_text="{ item }">
                            {{ item.destiny_text ? item.destiny_text : 'Todos' }}
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="primary" small depressed @click="get(item.id)" style="margin: 1px">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn color="error" small depressed @click="del(item.id, item.limitId)" style="margin: 1px">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="dialog" scrollable persistent max-width="1000px">
                    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
                        <v-card>
                            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                            <v-card-title class="text-center headline primary warning--text d-inline-block" style="color: #ffffff; word-break: normal; width: 100%">{{ form.limitId ? 'Editar limite ID: ' + form.limitId : 'Agregar Limite' }}</v-card-title>
                            <v-card-text>
                                <v-row justify="center">
                                    <v-col cols="12" sm="10" md="8">
                                        <v-select
                                            :label="labels.type"
                                            :items="types"
                                            name="type"
                                            v-model="form.type"
                                            :error-messages="errors.type"
                                            :disabled="loading"
                                            :readonly="form.id"
                                            :rules="[rules.required('type')]"
                                            @select="clearErrors('type')"
                                            prepend-icon="mdi-plus-minus-box"
                                            item-text="name"
                                            item-value="code">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px">
                                                        <v-icon>mdi-{{ item.icon }}</v-icon>
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <v-icon>mdi-{{ item.icon }}</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="5">
                                        <v-currency-field
                                            :label="labels.value"
                                            v-model="form.value"
                                            name="value"
                                            :error-messages="errors.value"
                                            :rules="[rules.required('value')]"
                                            :disabled="loading"
                                            prepend-icon="mdi-cash-check"
                                            @input="clearErrors('value')"
                                            v-bind="currency_config"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.currency"
                                            :items="currencies"
                                            name="currency"
                                            hint="Opcional"
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            v-model="form.currency"
                                            :error-messages="errors.currency"
                                            :disabled="loading"
                                            :rules="[]"
                                            @select="clearErrors('currency')"
                                            prepend-icon="mdi-currency-sign"
                                            item-text="name"
                                            item-value="code"/>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.country"
                                            :items="countries"
                                            name="country"
                                            hint="Opcional"
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            v-model="form.country"
                                            :error-messages="errors.country"
                                            :disabled="loading"
                                            :rules="[]"
                                            @select="clearErrors('country')"
                                            prepend-icon="mdi-resistor-nodes"
                                            item-text="name"
                                            item-value="code">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px">
                                                        <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="5">
                                        <v-select
                                            :label="labels.destiny"
                                            :items="countries"
                                            name="destiny"
                                            hint="Opcional"
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            v-model="form.destiny"
                                            :error-messages="errors.destiny"
                                            :disabled="loading"
                                            :rules="[]"
                                            @select="clearErrors('destiny')"
                                            prepend-icon="mdi-resistor-nodes"
                                            item-text="name"
                                            item-value="code">
                                            <template v-slot:selection="{ item }">
                                                <v-flex>
                                                    <v-avatar size="28px">
                                                        <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                                    </v-avatar>
                                                    <span class='ml-1'>{{ item.name }}</span>
                                                </v-flex>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar>
                                                    <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="item.name"/>
                                                </v-list-item-content>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="close(1)">
                                    <v-icon>mdi-close-circle</v-icon>&nbsp;&nbsp;Cerrar
                                </v-btn>
                                <v-spacer/>
                                <v-btn type="submit" :loading="loading" color="success" :disabled="loading || !valid">
                                    <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;{{ form.id ? 'Actualizar' : 'Guardar' }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../../../helpers/form";

    export default {
        name: "configLimits",
        mixins: [ Form ],
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Valor', value: 'amount', align: 'right', sortable: false },
                { text: 'Moneda', value: 'currency' },
                { text: 'Categoria', value: 'type_text' },
                { text: 'Origen', value: 'country_text' },
                { text: 'Destino', value: 'destiny_text' },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: false,
            noDataText: 'No tienes limites registrados',
            footerProps: {
                itemsPerPageOptions: [25, 50, 100, -1]
            },
            dialog: false,
            labels: {
                type: 'Categoria',
                value: 'Valor',
                currency: 'Moneda',
                country: 'Origen',
                destiny: 'Destino'
            },
            form: {
                id: null,
                limitId: null,
                type: null,
                value: null,
                currency: null,
                country: null,
                destiny: null
            },
            types: [
                { name: 'Minimo', code: 'min', icon: 'minus-circle' },
                { name: 'Maximo', code: 'max', icon: 'plus-circle' }
            ],
            countries: [
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Mexico', code: 'MEX' },
                { name: 'Panama', code: 'PAN' },
                { name: 'Peru', code: 'PER' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'Venezuela', code: 'VEN' }
            ],
            currencies: [
                { name: 'Bolivares', code: 'VES' },
                { name: 'Nuevos Soles', code: 'PEN' },
                { name: 'Pesos Chilenos', code: 'CLP' },
                { name: 'Pesos Colombianos', code: 'COL' },
                { name: 'Pesos Mexicanos', code: 'MXN' },
                { name: 'Dolares', code: 'USD' },
                { name: 'Dolares (Tether)', code: 'USDT' }
            ],
            currency_config: {
                locale: 'de-DE',
                decimalLength: 2,
                autoDecimalMode: false,
                min: null,
                max: null,
                defaultValue: 0
            }
        }),
        created () {
            this.getData();
        },
        methods: {
            getData(v) {
                clearTimeout(this.timeout);
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.get('/api/settings/limits').then((res) => {
                    this.desserts = res.data.limits;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
                this.timeout = setTimeout(this.getData, 60000);
            },
            get(id) {
                if (id) {
                    const items = this.desserts;

                    for (let i = 0; i < items.length; i++) {
                        if (items[i].id === id) {
                            this.form = items[i];
                            this.form.value = parseFloat(items[i].value);
                        }
                    }
                }
                this.dialog = true;
            },
            close(v) {
                this.form = {
                    id: null,
                    limitId: null,
                    type: null,
                    value: null,
                    currency: null,
                    country: null,
                    destiny: null
                };
                if (v === 1) {
                    this.dialog = false;
                    this.$refs.form.reset();
                }
            },
            del(v, fv) {
                this.$swal({
                    title: '¿Esta seguro de eliminar esta configuración de limite?',
                    text: 'Limite ID: ' + fv,
                    type: 'warning',
                    confirmButtonText: 'Si, eliminar',
                    cancelButtonText: 'No, cancelar'
                }).then((result) => {
                    if (result.value) {
                        axios.get('/api/settings/limits/' + v).then((res) => {
                            if (res.data.v === 'success') {
                                this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                                this.getData();
                                this.$swal({
                                    title: '¡Felicitaciones!',
                                    text: 'El limite con ID: ' + fv + ' se ha eliminada exitosamente',
                                    type: 'success',
                                    showConfirmButton: false,
                                    timer: 5000
                                });
                            } else {
                                this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                            }
                        }).catch(err => {
                            this.handleErrors(err.response.data.errors);
                        }).then(() => {
                            this.loading = false;
                        });
                    }
                });
            },
            submit() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    let url = '/api/settings/limits';

                    if (this.form.id) {
                        url += '/'+ this.form.id;
                    }
                    axios.post(url, this.form).then(res => {
                        if (res.data.v === 'success') {
                            this.$toast.success(res.data.status, {icon: 'mdi-check-circle'});
                        } else {
                            this.$toast.warning(res.data.status, {
                                timeout: 8000,
                                icon: 'mdi-information',
                                dismissable: false,
                                showClose: true
                            });
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.getData();
                        this.loading = false;
                        this.close(1);
                    });
                }
            }
        },
        destroyed() {
            clearTimeout(this.timeout);
        }
    }
</script>

<style scoped>

</style>
