<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2" style="word-break: normal">TRANSFERENCIAS REALIZADAS
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getApproved(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps" class="mb-12 mb-lg-0">
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item.id }}</strong>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                        </template>
                        <template v-slot:item.nd="{ item }">
                            <span>{{ item.td + ' ' + item.nd }}</span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <strong>
                                <v-btn color="info" small depressed dark @click="getVoucher(item.id)" style="margin: 1px">
                                    <v-icon>mdi-image</v-icon>
                                </v-btn>
                                <v-btn color="secondary" small depressed dark @click="getChangeVoucher(item.id)" style="margin: 1px" v-if="user.role === 'Administrator'">
                                    <v-icon>mdi-image-edit</v-icon>
                                </v-btn>
                                <v-btn color="error" small depressed @click="getRestore(item.id)" style="margin: 1px" v-if="user.role === 'Administrator'">
                                    <v-icon>mdi-restore-alert</v-icon>
                                </v-btn>
                                <v-btn color="primary" small depressed dark :to="'/history/transfer/' + item.id" style="margin: 1px">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </strong>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="view" persistent scrollable max-width="1000" width="80%">
                    <v-card>
                        <v-card-title class="text-center headline primary warning--text d-inline-block"
                                      style="color: #ffffff; word-break: normal; width: 100%">Comprobante ID: {{ voucher_id }}</v-card-title>
                        <v-card-text class="py-4">
                            <v-skeleton-loader class="mx-auto" width="100%" type="image" v-if="voucher === null"/>
                            <img :src="'/' + voucher" :alt="voucher_id" width="100%" v-else/>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn color="error" @click="closeVoucher"><v-icon>mdi-close-circle</v-icon> Cerrar</v-btn>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="changeVoucher" persistent scrollable max-width="1000" width="80%" v-if="user.role === 'Administrator'">
                    <v-form ref="form" @submit.prevent="submitChangeVoucher" lazy-validation v-model="valid">
                        <v-card>
                            <v-progress-linear indeterminate fixed v-if="loading" style="top:0"/>
                            <v-card-title class="text-center headline primary warning--text d-inline-block"
                                          style="color: #ffffff; word-break: normal; width: 100%">Comprobante ID: {{ voucher_id }}</v-card-title>
                            <v-card-text class="py-4">
                                <v-row justify="center">
                                    <v-col cols="12" md="8">
                                        <h2 class="text-center grey--text text--darken-2 pb-4" v-if="newVoucher === null">Agregar Comprobante (Ctrl + V)</h2>
                                        <h2 class="text-center success--text pb-4" v-else>
                                            <u>Comprobante Agregado</u>
                                        </h2>
                                        <v-skeleton-loader class="mx-auto" width="100%" type="image" v-if="newVoucher === null"/>
                                        <img :src="newVoucherUrl" :alt="voucher_id" width="100%" v-else/>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-btn color="error" @click="closeChangeVoucher">
                                    <v-icon>mdi-close-circle</v-icon> Cerrar
                                </v-btn>
                                <v-spacer/>
                                <v-btn type="submit" :loading="loading" :disabled="loading || !valid || newVoucher === null" color="success">
                                    <v-icon>mdi-check-circle</v-icon> Actualizar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from '../../../helpers/form';

    export default {
        name: "TApproved",
        mixins: [Form],
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'ID', value: 'id', align: 'right' },
                { text: 'Nombre', value: 'name', align: 'right', sortable: false },
                { text: 'Banco', value: 'bank_name', align: 'right', sortable: false },
                { text: 'Valor', value: 'amount_receiver_text', align: 'right', sortable: false },
                { text: 'Moneda', value: 'currency_receiver', align: 'left', sortable: false },
                { text: 'Destino', value: 'country_receiver_text', align: 'left', sortable: false },
                { text: 'Fecha', value: 'updated_at', align: 'right', sortable: false },
                { text: 'Acción', value: 'action', align: 'left', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: true,
            noDataText: 'No hay transferencias realizadas',
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            voucher_id: null,
            voucher: null,
            view: false,
            newVoucher: null,
            newVoucherUrl: null,
            changeVoucher: false
        }),
        created () {
            this.getApproved();
        },
        mounted() {
            window.addEventListener("paste", this.getPasteVoucher);
        },
        methods: {
            getApproved(v) {
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.get('/api/payer/approved').then((res) => {
                    if (res.data.transfers) {
                        this.desserts = res.data.transfers;
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
            },
            getRestore(id) {

            },
            getVoucher(id) {
                axios.get('/api/history/v2/' + id).then((res) => {
                    this.voucher_id = id;
                    this.voucher = res.data.voucher;
                    this.view = true;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                });
            },
            closeVoucher() {
                this.view = false;
                this.voucher_id = null;
                this.voucher = null;
            },
            getChangeVoucher(id) {
                this.voucher_id = id;
                this.changeVoucher = true;
            },
            getPasteVoucher(e) {
                for (let i = 0; i < e.clipboardData.items.length; i++) {
                    let clipboardItem = e.clipboardData.items[0];
                    let type = clipboardItem.type;

                    if (type.indexOf("image") !== -1) {
                        let blob = clipboardItem.getAsFile();
                        this.newVoucherUrl = URL.createObjectURL(blob);

                        let reader = new window.FileReader();
                        reader.readAsDataURL(clipboardItem.getAsFile());

                        reader.onload = e => {
                            this.newVoucher = e.target.result;
                        }
                    } else {
                        console.log('Los datos tipo ' + type + ' no son soportados');
                    }
                }
            },
            submitChangeVoucher() {
                this.loading = true;

                let formData = new FormData();
                formData.append('type', 'Customer');
                formData.append('voucher', this.newVoucher);

                    axios.post('/api/payer/voucher/' + this.voucher_id, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }).then(res => {
                        if (res.data.v === 'success') {
                            this.closeChangeVoucher();
                            this.$toast.success(res.data.status, { icon: 'mdi-check-circle' });
                        } else {
                            this.$toast.warning(res.data.status, { icon: 'mdi-information' });
                        }
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    })
            },
            closeChangeVoucher() {
                this.changeVoucher = false;
                this.newVoucherUrl = null;
                this.newVoucher = null;
                this.voucher_id = null;
            }
        },
        destroyed() {
            window.removeEventListener('paste', this.getPasteVoucher);
        }
    }
</script>

<style scoped>

</style>
