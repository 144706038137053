<template>
    <v-container class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">MOVIMIENTOS CUENTA: {{ account }}
                    </v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getData(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2"
                                  style="word-break: normal">SALDO: {{ balance }}
                    </v-card-title>
                    <v-divider/>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.created_at="{ item }">
                            <b>{{ item.created_at }}</b>
                        </template>
                        <template v-slot:item.amount="{ item }">
                            <v-chip class="error--text" v-if="item.type === '-'">
                                <b>{{ item.amount }}</b>
                            </v-chip>
                            <v-chip class="success--text" v-else-if="item.type === '+'">
                                <b>{{ item.amount }}</b>
                            </v-chip>
                        </template>
                        <template v-slot:item.balance="{ item }">
                            <b>{{ item.balance }}</b>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="primary" small depressed @click="viewer(item.id)" style="margin: 1px"
                                   v-if="item.voucher">
                                <v-icon>mdi-panorama</v-icon>
                            </v-btn>
                            <v-btn color="primary" small depressed disabled style="margin: 1px" v-else>
                                <v-icon>mdi-panorama</v-icon>
                            </v-btn>
                            <v-btn color="error" small depressed @click="trash(item.id)" style="margin: 1px"
                                   v-if="item.transfer_id === null && item.payment_id === null">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            <v-btn color="error" small depressed disabled style="margin: 1px" v-else>
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="view" scrollable max-width="1000" width="80%">
                    <v-card>
                        <v-card-title class="text-center headline primary warning--text d-inline-block"
                                      style="color: #ffffff; word-break: normal; width: 100%">Comprobante Movimiento ID: {{ voucherId }}</v-card-title>
                        <v-card-text>
                            <img :src="'/' + voucher" :alt="voucherId" width="100%" />
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn color="error" @click="close"><v-icon>mdi-close-circle</v-icon> Cerrar</v-btn>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../../../helpers/form";

    export default {
        name: "BankMovements",
        mixins: [Form],
        props: ['user'],
        data: () => ({
            account: null,
            balance: null,
            search: '',
            headers: [
                { text: 'FECHA', value: 'created_at', sortable: false },
                { text: 'DETALLES', value: 'details', sortable: false },
                { text: 'INGRESO/SALIDA', value: 'amount', align: 'end' },
                { text: 'SALDO', value: 'balance', align: 'end' },
                { text: 'ACCION', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: true,
            noDataText: 'No tienes proveedores registrados',
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            view: false,
            voucherId: null,
            voucher: null
        }),
        created() {
            this.getData();
        },
        methods: {
            getData(v) {
                clearTimeout(this.timeout);
                this.loading = true;

                if (v === 1) { this.desserts = []; }
                axios.get('/api/movements/accounts/' + this.$route.params.id).then((res) => {
                    if (res.data.items) {
                        this.account = res.data.account;
                        this.desserts = res.data.items;
                        this.balance = res.data.balance;
                    } else {
                        this.$toast.error(res.data.status, {icon: 'mdi-close-circle'});
                        this.$router.push({name: 'Home'});
                    }
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
                this.timeout = setTimeout(this.getData, 60000);
            },
            close() {
                this.view = false;
                this.voucherId = null;
                this.voucher = null;
            },
            viewer(id) {
                const items = this.desserts;
                for (let i = 0; i < items.length; i++) {
                    if (items[i].id === id) {
                        this.voucherId = id;
                        this.voucher = items[i].voucher;
                    }
                }
                this.view = true;
            }
        }
    }
</script>

<style scoped>

</style>
