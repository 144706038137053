<template>
    <v-container>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate width="6" size="48"></v-progress-circular>
        </v-overlay>
        <v-row justify="center">
            <v-col cols="12" class="pt-0 px-0">
                <v-card width="100%">
                    <v-progress-linear v-model="skill" color="primary" height="25" reactive :dark="skill > 49">
                        <template v-slot="{ value }">
                            <strong>{{ Math.ceil(value) }}%</strong>
                        </template>
                    </v-progress-linear>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" class="pb-0 px-0">
                <v-stepper outlined v-model="e1">
                    <v-stepper-header class="primary warning--text text-center pa-3" style="height: auto">
                        <h3 class="text-center" style="width: 100%">
                            <strong>Disponible para Transferir: <br v-show="$vuetify.breakpoint.xsOnly">{{ (form.Amount && (form.Amount > min) ? Number(balance).toLocaleString("de-DE") : '0') + ' ' + (form.Currency ? form.Currency : '') }}</strong>
                        </h3>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">

                            <v-stepper-content class="px-2 px-md-4" step="1">
                                <v-card class="mb-12" outlined color="transparent">
                                    <v-row justify="center">
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                :label="labels.Currency"
                                                :items="cs"
                                                name="Currency"
                                                v-model="form.Currency"
                                                :error-messages="errors.Currency"
                                                :disabled="loading"
                                                :rules="[rules.req('Currency')]"
                                                @select="clearErrors('Currency')"
                                                prepend-icon="mdi-hand-coin"
                                                item-text="name"
                                                item-value="code">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <span class="warning--text subtitle-2 text-center"
                                                                  style="width: 100%">{{ item.symbol }}</span>
                                                        </v-avatar>
                                                        <span class="ml-1">{{ item.name }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <span class="warning--text subtitle-1 text-center" style="width: 100%">
                                                            <strong>{{ item.symbol }}</strong>
                                                        </span>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-currency-field
                                                :label="labels.Amount"
                                                v-model="form.Amount"
                                                name="Amount"
                                                :error-messages="errors.Amount"
                                                :rules="[rules.required('Amount'), rules.minus('Amount', form.Amount, min, 'El'), rules.maxim('Amount', form.Amount, max, 'El')]"
                                                :disabled="loading"
                                                @change="clearErrors('Amount')"
                                                prepend-icon="mdi-cash"
                                                v-bind="currency_config"/>
                                        </v-col>
                                        <v-col cols="12" md="10">
                                            <v-subheader class="pb-2">{{ labels.Qty }}</v-subheader>
                                            <v-slider
                                                ref="Qty"
                                                v-model="form.Qty"
                                                :tick-labels="['1', '2', '3', '4', '5']"
                                                color="primary"
                                                thumb-color="primary"
                                                track-color="warning"
                                                thumb-label="always"
                                                :thumb-size="18"
                                                ticks="always"
                                                tick-size="7"
                                                :disabled="loading"
                                                :rules="[rules.required('Qty')]"
                                                :error-messages="errors.Qty"
                                                min="1"
                                                :max="QtyMax">
                                                <template v-slot:thumb-label>
                                                    <v-icon color="warning" size="12">mdi-check-bold</v-icon>
                                                </template>
                                                <template v-slot:prepend>
                                                    <v-icon color="primary" size="32" @click="decrement">mdi-minus-circle</v-icon>
                                                </template>
                                                <template v-slot:append>
                                                    <v-icon color="primary" size="32" @click="increment">mdi-plus-circle</v-icon>
                                                </template>
                                            </v-slider>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn color="tertiary" dark @click="$router.go(-1)">Salir</v-btn>
                                <v-btn class="float-right" color="primary" :disabled="balance < 0" @click="next(1)">Continuar</v-btn>
                            </v-stepper-content>

                            <v-stepper-content class="px-2 px-md-4" step="2">
                                <v-card class="mb-12" outlined color="transparent">
                                    <v-row justify="center">
                                        <v-col cols="12">
                                            <h3 class="text-center"><strong>Transferencia Nº 1</strong></h3>
                                        </v-col>
                                        <v-col :cols="(edit_1 ? '10': '12')" sm="9" lg="8">
                                            <v-autocomplete
                                                v-if="e1 > 1"
                                                :label="labels.account"
                                                :items="accounts"
                                                name="account_1"
                                                placeholder="Registrar nueva cuenta"
                                                v-model="form.account_1"
                                                :error-messages="errors.account_1"
                                                :disabled="loading"
                                                clearable
                                                clear-icon="mdi-close-circle"
                                                :rules="[]"
                                                @select="clearErrors('account_1')"
                                                prepend-icon="mdi-clipboard-text"
                                                item-text="bank_details"
                                                item-value="id">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>{{ item.name + ' ' + item.bank_details }}</v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">mdi-briefcase-account</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name + '<br>' + item.bank_details"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="3" lg="2" align-self="center" v-if="form.account_1">
                                            <v-btn color="primary" class="px-0" block depressed @click="edit_1 = false">
                                                <v-icon>mdi-pencil</v-icon>
                                                <span v-show="$vuetify.breakpoint.smAndUp">Editar</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" :sm="form.bn_1 === 'Nuestras Oficinas' ? 12 : 6">
                                            <v-text-field
                                                v-if="e1 > 1"
                                                :label="labels.name"
                                                v-model="form.name_1"
                                                type="text"
                                                name="name_1"
                                                :error-messages="errors.name_1"
                                                :rules="[rules.required('name'), rules.min('name', 3, 'El'), rules.max('name', 100, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_1"
                                                prepend-icon="mdi-account-circle"
                                                @input="clearErrors('name_1')"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-autocomplete
                                                v-if="e1 > 1"
                                                :label="labels.bank"
                                                :items="banks"
                                                name="bank_1"
                                                v-model="form.bank_1"
                                                :error-messages="errors.bank_1"
                                                :disabled="loading"
                                                :readonly="edit_1"
                                                :rules="[rules.required('bank')]"
                                                @select="clearErrors('bank_1')"
                                                prepend-icon="mdi-domain"
                                                item-text="name"
                                                item-value="code"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="form.bn_1 === 'Nuestras Oficinas'">
                                            <v-autocomplete
                                                v-if="e1 > 1"
                                                :label="labels.branch"
                                                :items="branches"
                                                name="branch_1"
                                                v-model="form.branch_1"
                                                :error-messages="errors.branch_1"
                                                :disabled="loading"
                                                :readonly="edit_1"
                                                :rules="[rules.required('branch')]"
                                                @select="clearErrors('branch_1')"
                                                prepend-icon="mdi-store"
                                                item-text="name"
                                                item-value="id"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                v-if="e1 > 1"
                                                :label="labels.td"
                                                :items="td"
                                                name="td_1"
                                                v-model="form.td_1"
                                                :error-messages="errors.td_1"
                                                :disabled="loading"
                                                :readonly="edit_1"
                                                :rules="[rules.required('td')]"
                                                @select="clearErrors('td_1')"
                                                prepend-icon="mdi-clipboard-text"
                                                item-text="name"
                                                item-value="value">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <span class="warning--text subtitle-2 text-center"
                                                                  style="width: 100%">{{ item.value }}</span>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.name }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <span class="warning--text subtitle-1 text-center" style="width: 100%">
                                                            <strong>{{ item.value }}</strong>
                                                        </span>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="destiny === 'CHL' && (form.td_1 === 'CI' || form.td_1 === 'CE')">
                                            <v-text-field
                                                v-if="e1 > 1"
                                                :label="labels.nd"
                                                v-model="form.nd_1"
                                                type="text"
                                                name="nd_1"
                                                :error-messages="errors.nd_1"
                                                :rules="[rules.required('nd'), rules.equal('nd', 10, 9, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_1"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_1')"
                                                v-mask="rut"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else-if="form.td_1 === 'PAS'">
                                            <v-text-field
                                                v-if="e1 > 1"
                                                :label="labels.nd"
                                                v-model="form.nd_1"
                                                type="text"
                                                name="nd_1"
                                                :error-messages="errors.nd_1"
                                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_1"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_1')"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else>
                                            <v-text-field
                                                v-if="e1 > 1"
                                                :label="labels.nd"
                                                v-model="form.nd_1"
                                                type="number"
                                                name="nd_1"
                                                :error-messages="errors.nd_1"
                                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 12, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_1"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_1')"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="form.bn_1 !== 'Nuestras Oficinas'">
                                            <v-select
                                                v-if="e1 > 1"
                                                :label="labels.tc"
                                                :items="tc"
                                                name="tc_1"
                                                v-model="form.tc_1"
                                                :error-messages="errors.tc_1"
                                                :disabled="loading"
                                                :readonly="edit_1"
                                                :rules="[rules.required('tc')]"
                                                @select="clearErrors('tc_1')"
                                                prepend-icon="mdi-wallet"
                                                item-text="name"
                                                item-value="value">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <v-icon class="warning--text">mdi-account-convert</v-icon>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.name }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">mdi-account-convert</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="destiny === 'VEN' && form.bn_1 !== 'Nuestras Oficinas'">
                                            <v-text-field
                                                v-if="e1 > 1"
                                                :label="labels.nc"
                                                v-model="form.nc_1"
                                                type="tel"
                                                name="nc_1"
                                                :prefix="form.bank_1"
                                                :error-messages="errors.nc_1"
                                                :rules="[rules.required('nc'), rules.minT('nc', 19, 20, 'El'), rules.maxT('nc', 19, 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_1"
                                                prepend-icon="mdi-dialpad"
                                                @input="clearErrors('nc_1')"
                                                v-mask="mask"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else-if="form.bn_1 !== 'Nuestras Oficinas'">
                                            <v-text-field
                                                v-if="e1 > 1"
                                                :label="labels.nc"
                                                v-model="form.nc_1"
                                                type="tel"
                                                name="nc_1"
                                                :error-messages="errors.nc_1"
                                                :rules="[rules.required('nc'), rules.min('nc', 5, 'El'), rules.max('nc', 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_1"
                                                prepend-icon="mdi-dialpad"
                                                @input="clearErrors('nc_1')"/>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" sm="4" v-if="form.Qty > 1">
                                            <v-currency-field
                                                v-if="e1 > 1"
                                                :label="labels.Base"
                                                v-model="form.amount_1"
                                                name="amount_1"
                                                :error-messages="errors.amount_1"
                                                :rules="[rules.required('Base')]"
                                                :disabled="loading"
                                                @input="clearErrors('amount_1')"
                                                prepend-icon="mdi-cash-multiple"
                                                v-bind="currency_config"/>
                                        </v-col>
                                        <template v-for="item in form.quotes_1">
                                            <v-col cols="6" sm="4" align-self="center">
                                                <v-btn
                                                    :outlined="item.value !== form.convert_1 && item.cReceiver !== form.cConvert_1"
                                                    @click="getConvert(1, item.value, item.cReceiver)"
                                                    color="primary"
                                                    :class="(item.value !== form.convert_1 && item.cReceiver !== form.cConvert_1 ? 'primary' : 'warning') + '--text'"
                                                    block
                                                    x-large>
                                                    <strong>{{ Number(item.value).toLocaleString("de-DE") + ' ' + item.cReceiver }}</strong>
                                                </v-btn>
                                            </v-col>
                                        </template>
                                        <v-col cols="12" md="10">
                                            <h4 class="text-center">
                                                <strong>Por favor revise bien la información antes de continuar</strong>
                                            </h4>
                                            <h4 class="text-center error--text" v-if="balance < 0">
                                                <strong>El monto a transferir no puede ser mayor al valor depositado</strong>
                                            </h4>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn color="tertiary" dark @click="back(2)">Atras</v-btn>
                                <v-btn class="float-right" color="primary" :disabled="balance < 0" @click="next(2)">Continuar</v-btn>
                            </v-stepper-content>

                            <v-stepper-content class="px-2 px-md-4" step="3" v-if="form.Qty > 1">
                                <v-card class="mb-12" outlined color="transparent">
                                    <v-row justify="center">
                                        <v-col cols="12">
                                            <h3 class="text-center"><strong>Transferencia Nº 2</strong></h3>
                                        </v-col>
                                        <v-col :cols="(edit_2 ? '10': '12')" sm="9" lg="8">
                                            <v-autocomplete
                                                v-if="e1 > 2"
                                                :label="labels.account"
                                                :items="accounts"
                                                name="account_2"
                                                placeholder="Registrar nueva cuenta"
                                                v-model="form.account_2"
                                                :error-messages="errors.account_2"
                                                :disabled="loading"
                                                clearable
                                                clear-icon="mdi-close-circle"
                                                :rules="[]"
                                                @select="clearErrors('account_2')"
                                                prepend-icon="mdi-clipboard-text"
                                                item-text="bank_details"
                                                item-value="id">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>{{ item.name + ' ' + item.bank_details }}</v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">mdi-briefcase-account</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name + '<br>' + item.bank_details"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="3" lg="2" align-self="center" v-if="form.account_2">
                                            <v-btn color="primary" class="px-0" block depressed @click="edit_2 = false">
                                                <v-icon>mdi-pencil</v-icon>
                                                <span v-show="$vuetify.breakpoint.smAndUp">Editar</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" :sm="form.bn_2 === 'Nuestras Oficinas' ? 12 : 6">
                                            <v-text-field
                                                v-if="e1 > 2"
                                                :label="labels.name"
                                                v-model="form.name_2"
                                                type="text"
                                                name="name_2"
                                                :error-messages="errors.name_2"
                                                :rules="[rules.required('name'), rules.min('name', 3, 'El'), rules.max('name', 100, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_2"
                                                prepend-icon="mdi-account-circle"
                                                @input="clearErrors('name_2')"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-autocomplete
                                                v-if="e1 > 2"
                                                :label="labels.bank"
                                                :items="banks"
                                                name="bank_2"
                                                v-model="form.bank_2"
                                                :error-messages="errors.bank_2"
                                                :disabled="loading"
                                                :readonly="edit_2"
                                                :rules="[rules.required('bank')]"
                                                @select="clearErrors('bank_2')"
                                                prepend-icon="mdi-domain"
                                                item-text="name"
                                                item-value="code"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="form.bn_2 === 'Nuestras Oficinas'">
                                            <v-autocomplete
                                                v-if="e1 > 2"
                                                :label="labels.branch"
                                                :items="branches"
                                                name="branch_2"
                                                v-model="form.branch_2"
                                                :error-messages="errors.branch_2"
                                                :disabled="loading"
                                                :readonly="edit_2"
                                                :rules="[rules.required('branch')]"
                                                @select="clearErrors('branch_2')"
                                                prepend-icon="mdi-store"
                                                item-text="name"
                                                item-value="id"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                v-if="e1 > 2"
                                                :label="labels.td"
                                                :items="td"
                                                name="td_2"
                                                v-model="form.td_2"
                                                :error-messages="errors.td_2"
                                                :disabled="loading"
                                                :readonly="edit_2"
                                                :rules="[rules.required('td')]"
                                                @select="clearErrors('td_2')"
                                                prepend-icon="mdi-clipboard-text"
                                                item-text="name"
                                                item-value="value">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <span class="warning--text subtitle-2 text-center"
                                                                  style="width: 100%">{{ item.value }}</span>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.name }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <span class="warning--text subtitle-1 text-center" style="width: 100%">
                                                            <strong>{{ item.value }}</strong>
                                                        </span>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="destiny === 'CHL' && (form.td_2 === 'CI' || form.td_2 === 'CE')">
                                            <v-text-field
                                                v-if="e1 > 2"
                                                :label="labels.nd"
                                                v-model="form.nd_2"
                                                type="text"
                                                name="nd_2"
                                                :error-messages="errors.nd_2"
                                                :rules="[rules.required('nd'), rules.equal('nd', 10, 9, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_2"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_2')"
                                                v-mask="rut"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else-if="form.td_2 === 'PAS'">
                                            <v-text-field
                                                v-if="e1 > 2"
                                                :label="labels.nd"
                                                v-model="form.nd_2"
                                                type="text"
                                                name="nd_2"
                                                :error-messages="errors.nd_2"
                                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_2"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_2')"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else>
                                            <v-text-field
                                                v-if="e1 > 2"
                                                :label="labels.nd"
                                                v-model="form.nd_2"
                                                type="number"
                                                name="nd_2"
                                                :error-messages="errors.nd_2"
                                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 12, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_2"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_2')"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="form.bn_2 !== 'Nuestras Oficinas'">
                                            <v-select
                                                v-if="e1 > 2"
                                                :label="labels.tc"
                                                :items="tc"
                                                name="tc_2"
                                                v-model="form.tc_2"
                                                :error-messages="errors.tc_2"
                                                :disabled="loading"
                                                :readonly="edit_2"
                                                :rules="[rules.required('tc')]"
                                                @select="clearErrors('tc_2')"
                                                prepend-icon="mdi-wallet"
                                                item-text="name"
                                                item-value="value">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <v-icon class="warning--text">mdi-account-convert</v-icon>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.name }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">mdi-account-convert</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="destiny === 'VEN' && form.bn_2 !== 'Nuestras Oficinas'">
                                            <v-text-field
                                                v-if="e1 > 2"
                                                :label="labels.nc"
                                                v-model="form.nc_2"
                                                type="tel"
                                                name="nc_2"
                                                :prefix="form.bank_2"
                                                :error-messages="errors.nc_2"
                                                :rules="[rules.required('nc'), rules.minT('nc', 19, 20, 'El'), rules.maxT('nc', 19, 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_2"
                                                prepend-icon="mdi-dialpad"
                                                @input="clearErrors('nc_2')"
                                                v-mask="mask"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else-if="form.bn_2 !== 'Nuestras Oficinas'">
                                            <v-text-field
                                                v-if="e1 > 2"
                                                :label="labels.nc"
                                                v-model="form.nc_2"
                                                type="tel"
                                                name="nc_2"
                                                :error-messages="errors.nc_2"
                                                :rules="[rules.required('nc'), rules.min('nc', 5, 'El'), rules.max('nc', 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_2"
                                                prepend-icon="mdi-dialpad"
                                                @input="clearErrors('nc_2')"/>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" sm="4">
                                            <v-currency-field
                                                v-if="e1 > 2"
                                                :label="labels.Base"
                                                v-model="form.amount_2"
                                                name="amount_2"
                                                :error-messages="errors.amount_2"
                                                :rules="[rules.required('Base')]"
                                                :disabled="loading"
                                                @input="clearErrors('amount_2')"
                                                prepend-icon="mdi-cash-multiple"
                                                v-bind="currency_config"
                                            />
                                        </v-col>
                                        <template v-for="item in form.quotes_2">
                                            <v-col cols="6" sm="4" align-self="center">
                                                <v-btn
                                                    :outlined="item.value !== form.convert_2 && item.cReceiver !== form.cConvert_2"
                                                    @click="getConvert(2, item.value, item.cReceiver)"
                                                    color="primary"
                                                    :class="(item.value !== form.convert_2 && item.cReceiver !== form.cConvert_2 ? 'primary' : 'warning') + '--text'"
                                                    block
                                                    x-large>
                                                    <strong>{{ Number(item.value).toLocaleString("de-DE") + ' ' + item.cReceiver }}</strong>
                                                </v-btn>
                                            </v-col>
                                        </template>
                                        <v-col cols="12" md="10">
                                            <h4 class="text-center">
                                                <strong>Por favor revise bien la información antes de continuar</strong>
                                            </h4>
                                            <h4 class="text-center error--text" v-if="balance < 0">
                                                <strong>El monto a transferir no puede ser mayor al valor depositado</strong>
                                            </h4>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn color="tertiary" dark @click="back(3)">Atras</v-btn>
                                <v-btn class="float-right" color="primary" :disabled="balance < 0" @click="next(3)">Continuar</v-btn>
                            </v-stepper-content>

                            <v-stepper-content class="px-2 px-md-4" step="4" v-if="form.Qty > 2">
                                <v-card class="mb-12" outlined color="transparent">
                                    <v-row justify="center">
                                        <v-col cols="12">
                                            <h3 class="text-center"><strong>Transferencia Nº 3</strong></h3>
                                        </v-col>
                                        <v-col :cols="(edit_3 ? '10': '12')" sm="9" lg="8">
                                            <v-autocomplete
                                                v-if="e1 > 3"
                                                :label="labels.account"
                                                :items="accounts"
                                                name="account_3"
                                                placeholder="Registrar nueva cuenta"
                                                v-model="form.account_3"
                                                :error-messages="errors.account_3"
                                                :disabled="loading"
                                                clearable
                                                clear-icon="mdi-close-circle"
                                                :rules="[]"
                                                @select="clearErrors('account_3')"
                                                prepend-icon="mdi-clipboard-text"
                                                item-text="bank_details"
                                                item-value="id">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>{{ item.name + ' ' + item.bank_details }}</v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">mdi-briefcase-account</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name + '<br>' + item.bank_details"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="3" lg="2" align-self="center" v-if="form.account_3">
                                            <v-btn color="primary" class="px-0" block depressed @click="edit_3 = false">
                                                <v-icon>mdi-pencil</v-icon>
                                                <span v-show="$vuetify.breakpoint.smAndUp">Editar</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" :sm="form.bn_3 === 'Nuestras Oficinas' ? 12 : 6">
                                            <v-text-field
                                                v-if="e1 > 3"
                                                :label="labels.name"
                                                v-model="form.name_3"
                                                type="text"
                                                name="name_3"
                                                :error-messages="errors.name_3"
                                                :rules="[rules.required('name'), rules.min('name', 3, 'El'), rules.max('name', 100, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_3"
                                                prepend-icon="mdi-account-circle"
                                                @input="clearErrors('name_3')"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-autocomplete
                                                v-if="e1 > 3"
                                                :label="labels.bank"
                                                :items="banks"
                                                name="bank_3"
                                                v-model="form.bank_3"
                                                :error-messages="errors.bank_3"
                                                :disabled="loading"
                                                :readonly="edit_3"
                                                :rules="[rules.required('bank')]"
                                                @select="clearErrors('bank_3')"
                                                prepend-icon="mdi-domain"
                                                item-text="name"
                                                item-value="code"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="form.bn_3 === 'Nuestras Oficinas'">
                                            <v-autocomplete
                                                v-if="e1 > 3"
                                                :label="labels.branch"
                                                :items="branches"
                                                name="branch_3"
                                                v-model="form.branch_3"
                                                :error-messages="errors.branch_3"
                                                :disabled="loading"
                                                :readonly="edit_3"
                                                :rules="[rules.required('branch')]"
                                                @select="clearErrors('branch_3')"
                                                prepend-icon="mdi-store"
                                                item-text="name"
                                                item-value="id"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                v-if="e1 > 3"
                                                :label="labels.td"
                                                :items="td"
                                                name="td_3"
                                                v-model="form.td_3"
                                                :error-messages="errors.td_3"
                                                :disabled="loading"
                                                :readonly="edit_3"
                                                :rules="[rules.required('td')]"
                                                @select="clearErrors('td_3')"
                                                prepend-icon="mdi-clipboard-text"
                                                item-text="name"
                                                item-value="value">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <span class="warning--text subtitle-2 text-center"
                                                                  style="width: 100%">{{ item.value }}</span>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.name }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <span class="warning--text subtitle-1 text-center" style="width: 100%">
                                                            <strong>{{ item.value }}</strong>
                                                        </span>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="destiny === 'CHL' && (form.td_3 === 'CI' || form.td_3 === 'CE')">
                                            <v-text-field
                                                v-if="e1 > 3"
                                                :label="labels.nd"
                                                v-model="form.nd_3"
                                                type="text"
                                                name="nd_3"
                                                :error-messages="errors.nd_3"
                                                :rules="[rules.required('nd'), rules.equal('nd', 10, 9, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_3"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_3')"
                                                v-mask="rut"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else-if="form.td_3 === 'PAS'">
                                            <v-text-field
                                                v-if="e1 > 3"
                                                :label="labels.nd"
                                                v-model="form.nd_3"
                                                type="text"
                                                name="nd_3"
                                                :error-messages="errors.nd_3"
                                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_3"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_3')"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else>
                                            <v-text-field
                                                v-if="e1 > 3"
                                                :label="labels.nd"
                                                v-model="form.nd_3"
                                                type="number"
                                                name="nd_3"
                                                :error-messages="errors.nd_3"
                                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 12, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_3"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_3')"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="form.bn_3 !== 'Nuestras Oficinas'">
                                            <v-select
                                                v-if="e1 > 3"
                                                :label="labels.tc"
                                                :items="tc"
                                                name="tc_3"
                                                v-model="form.tc_3"
                                                :error-messages="errors.tc_3"
                                                :disabled="loading"
                                                :readonly="edit_3"
                                                :rules="[rules.required('tc')]"
                                                @select="clearErrors('tc_3')"
                                                prepend-icon="mdi-wallet"
                                                item-text="name"
                                                item-value="value">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <v-icon class="warning--text">mdi-account-convert</v-icon>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.name }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">mdi-account-convert</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="destiny === 'VEN' && form.bn_3 !== 'Nuestras Oficinas'">
                                            <v-text-field
                                                v-if="e1 > 3"
                                                :label="labels.nc"
                                                v-model="form.nc_3"
                                                type="tel"
                                                name="nc_3"
                                                :prefix="form.bank_3"
                                                :error-messages="errors.nc_3"
                                                :rules="[rules.required('nc'), rules.minT('nc', 19, 20, 'El'), rules.maxT('nc', 19, 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_3"
                                                prepend-icon="mdi-dialpad"
                                                @input="clearErrors('nc_3')"
                                                v-mask="mask"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else-if="form.bn_3 !== 'Nuestras Oficinas'">
                                            <v-text-field
                                                v-if="e1 > 3"
                                                :label="labels.nc"
                                                v-model="form.nc_3"
                                                type="tel"
                                                name="nc_3"
                                                :error-messages="errors.nc_3"
                                                :rules="[rules.required('nc'), rules.min('nc', 5, 'El'), rules.max('nc', 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_3"
                                                prepend-icon="mdi-dialpad"
                                                @input="clearErrors('nc_3')"/>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" sm="4">
                                            <v-currency-field
                                                v-if="e1 > 3"
                                                :label="labels.Base"
                                                v-model="form.amount_3"
                                                name="amount_3"
                                                :error-messages="errors.amount_3"
                                                :rules="[rules.required('Base')]"
                                                :disabled="loading"
                                                @input="clearErrors('amount_3')"
                                                prepend-icon="mdi-cash-multiple"
                                                v-bind="currency_config"
                                            />
                                        </v-col>
                                        <template v-for="item in form.quotes_3">
                                            <v-col cols="6" sm="4" align-self="center">
                                                <v-btn
                                                    :outlined="item.value !== form.convert_3 && item.cReceiver !== form.cConvert_3"
                                                    @click="getConvert(3, item.value, item.cReceiver)"
                                                    color="primary"
                                                    :class="(item.value !== form.convert_3 && item.cReceiver !== form.cConvert_3 ? 'primary' : 'warning') + '--text'"
                                                    block
                                                    x-large>
                                                    <strong>{{ Number(item.value).toLocaleString("de-DE") + ' ' + item.cReceiver }}</strong>
                                                </v-btn>
                                            </v-col>
                                        </template>
                                        <v-col cols="12" md="10">
                                            <h4 class="text-center">
                                                <strong>Por favor revise bien la información antes de continuar</strong>
                                            </h4>
                                            <h4 class="text-center error--text" v-if="balance < 0">
                                                <strong>El monto a transferir no puede ser mayor al valor depositado</strong>
                                            </h4>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn color="tertiary" dark @click="back(4)">Atras</v-btn>
                                <v-btn class="float-right" color="primary" :disabled="balance < 0" @click="next(4)">Continuar</v-btn>
                            </v-stepper-content>

                            <v-stepper-content class="px-2 px-md-4" step="5" v-if="form.Qty > 3">
                                <v-card class="mb-12" outlined color="transparent">
                                    <v-row justify="center">
                                        <v-col cols="12">
                                            <h3 class="text-center"><strong>Transferencia Nº 4</strong></h3>
                                        </v-col>
                                        <v-col :cols="(edit_4 ? '10': '12')" sm="9" lg="8">
                                            <v-autocomplete
                                                v-if="e1 > 4"
                                                :label="labels.account"
                                                :items="accounts"
                                                name="account_4"
                                                placeholder="Registrar nueva cuenta"
                                                v-model="form.account_4"
                                                :error-messages="errors.account_4"
                                                :disabled="loading"
                                                clearable
                                                clear-icon="mdi-close-circle"
                                                :rules="[]"
                                                @select="clearErrors('account_4')"
                                                prepend-icon="mdi-clipboard-text"
                                                item-text="bank_details"
                                                item-value="id">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>{{ item.name + ' ' + item.bank_details }}</v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">mdi-briefcase-account</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name + '<br>' + item.bank_details"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="3" lg="2" align-self="center" v-if="form.account_4">
                                            <v-btn color="primary" class="px-0" block depressed @click="edit_4 = false">
                                                <v-icon>mdi-pencil</v-icon>
                                                <span v-show="$vuetify.breakpoint.smAndUp">Editar</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" :sm="form.bn_4 === 'Nuestras Oficinas' ? 12 : 6">
                                            <v-text-field
                                                v-if="e1 > 4"
                                                :label="labels.name"
                                                v-model="form.name_4"
                                                type="text"
                                                name="name_4"
                                                :error-messages="errors.name_4"
                                                :rules="[rules.required('name'), rules.min('name', 3, 'El'), rules.max('name', 100, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_4"
                                                prepend-icon="mdi-account-circle"
                                                @input="clearErrors('name_4')"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-autocomplete
                                                v-if="e1 > 4"
                                                :label="labels.bank"
                                                :items="banks"
                                                name="bank_4"
                                                v-model="form.bank_4"
                                                :error-messages="errors.bank_4"
                                                :disabled="loading"
                                                :readonly="edit_4"
                                                :rules="[rules.required('bank')]"
                                                @select="clearErrors('bank_4')"
                                                prepend-icon="mdi-domain"
                                                item-text="name"
                                                item-value="code"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="form.bn_4 === 'Nuestras Oficinas'">
                                            <v-autocomplete
                                                v-if="e1 > 4"
                                                :label="labels.branch"
                                                :items="branches"
                                                name="branch_4"
                                                v-model="form.branch_4"
                                                :error-messages="errors.branch_4"
                                                :disabled="loading"
                                                :readonly="edit_4"
                                                :rules="[rules.required('branch')]"
                                                @select="clearErrors('branch_4')"
                                                prepend-icon="mdi-store"
                                                item-text="name"
                                                item-value="id"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                v-if="e1 > 4"
                                                :label="labels.td"
                                                :items="td"
                                                name="td_4"
                                                v-model="form.td_4"
                                                :error-messages="errors.td_4"
                                                :disabled="loading"
                                                :readonly="edit_4"
                                                :rules="[rules.required('td')]"
                                                @select="clearErrors('td_4')"
                                                prepend-icon="mdi-clipboard-text"
                                                item-text="name"
                                                item-value="value">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <span class="warning--text subtitle-2 text-center"
                                                                  style="width: 100%">{{ item.value }}</span>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.name }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <span class="warning--text subtitle-1 text-center" style="width: 100%">
                                                            <strong>{{ item.value }}</strong>
                                                        </span>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="destiny === 'CHL' && (form.t_4 === 'CI' || form.td_4 === 'CE')">
                                            <v-text-field
                                                v-if="e1 > 4"
                                                :label="labels.nd"
                                                v-model="form.nd_4"
                                                type="text"
                                                name="nd_4"
                                                :error-messages="errors.nd_4"
                                                :rules="[rules.required('nd'), rules.equal('nd', 10, 9, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_4"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_4')"
                                                v-mask="rut"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else-if="form.td_4 === 'PAS'">
                                            <v-text-field
                                                v-if="e1 > 4"
                                                :label="labels.nd"
                                                v-model="form.nd_4"
                                                type="text"
                                                name="nd_4"
                                                :error-messages="errors.nd_4"
                                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_4"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_4')"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else>
                                            <v-text-field
                                                v-if="e1 > 4"
                                                :label="labels.nd"
                                                v-model="form.nd_4"
                                                type="number"
                                                name="nd_4"
                                                :error-messages="errors.nd_4"
                                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 12, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_4"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_4')"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="form.bn_4 !== 'Nuestras Oficinas'">
                                            <v-select
                                                v-if="e1 > 4"
                                                :label="labels.tc"
                                                :items="tc"
                                                name="tc_4"
                                                v-model="form.tc_4"
                                                :error-messages="errors.tc_4"
                                                :disabled="loading"
                                                :readonly="edit_4"
                                                :rules="[rules.required('tc')]"
                                                @select="clearErrors('tc_4')"
                                                prepend-icon="mdi-wallet"
                                                item-text="name"
                                                item-value="value">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <v-icon class="warning--text">mdi-account-convert</v-icon>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.name }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">mdi-account-convert</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="destiny === 'VEN' && form.bn_4 !== 'Nuestras Oficinas'">
                                            <v-text-field
                                                v-if="e1 > 4"
                                                :label="labels.nc"
                                                v-model="form.nc_4"
                                                type="tel"
                                                name="nc_4"
                                                :prefix="form.bank_4"
                                                :error-messages="errors.nc_4"
                                                :rules="[rules.required('nc'), rules.minT('nc', 19, 20, 'El'), rules.maxT('nc', 19, 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_4"
                                                prepend-icon="mdi-dialpad"
                                                @input="clearErrors('nc_4')"
                                                v-mask="mask"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else-if="form.bn_4 !== 'Nuestras Oficinas'">
                                            <v-text-field
                                                v-if="e1 > 4"
                                                :label="labels.nc"
                                                v-model="form.nc_4"
                                                type="tel"
                                                name="nc_4"
                                                :error-messages="errors.nc_4"
                                                :rules="[rules.required('nc'), rules.min('nc', 5, 'El'), rules.max('nc', 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_4"
                                                prepend-icon="mdi-dialpad"
                                                @input="clearErrors('nc_4')"/>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" sm="4">
                                            <v-currency-field
                                                v-if="e1 > 4"
                                                :label="labels.Base"
                                                v-model="form.amount_4"
                                                name="amount_4"
                                                :error-messages="errors.amount_4"
                                                :rules="[rules.required('Base')]"
                                                :disabled="loading"
                                                @input="clearErrors('amount_4')"
                                                prepend-icon="mdi-cash-multiple"
                                                v-bind="currency_config"
                                            />
                                        </v-col>
                                        <template v-for="item in form.quotes_4">
                                            <v-col cols="6" sm="4" align-self="center">
                                                <v-btn
                                                    :outlined="item.value !== form.convert_4 && item.cReceiver !== form.cConvert_4"
                                                    @click="getConvert(4, item.value, item.cReceiver)"
                                                    color="primary"
                                                    :class="(item.value !== form.convert_4 && item.cReceiver !== form.cConvert_4 ? 'primary' : 'warning') + '--text'"
                                                    block
                                                    x-large>
                                                    <strong>{{ Number(item.value).toLocaleString("de-DE") + ' ' + item.cReceiver }}</strong>
                                                </v-btn>
                                            </v-col>
                                        </template>
                                        <v-col cols="12" md="10">
                                            <h4 class="text-center">
                                                <strong>Por favor revise bien la información antes de continuar</strong>
                                            </h4>
                                            <h4 class="text-center error--text" v-if="balance < 0">
                                                <strong>El monto a transferir no puede ser mayor al valor depositado</strong>
                                            </h4>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn color="tertiary" dark @click="back(5)">Atras</v-btn>
                                <v-btn class="float-right" color="primary" :disabled="balance < 0" @click="next(5)">Continuar</v-btn>
                            </v-stepper-content>

                            <v-stepper-content class="px-2 px-md-4" step="6" v-if="form.Qty > 4">
                                <v-card class="mb-12" outlined color="transparent">
                                    <v-row justify="center">
                                        <v-col cols="12">
                                            <h3 class="text-center"><strong>Transferencia Nº 5</strong></h3>
                                        </v-col>
                                        <v-col :cols="(edit_5 ? '10': '12')" sm="9" lg="8">
                                            <v-autocomplete
                                                v-if="e1 > 5"
                                                :label="labels.account"
                                                :items="accounts"
                                                name="account_5"
                                                placeholder="Registrar nueva cuenta"
                                                v-model="form.account_5"
                                                :error-messages="errors.account_5"
                                                :disabled="loading"
                                                clearable
                                                clear-icon="mdi-close-circle"
                                                :rules="[]"
                                                @select="clearErrors('account_5')"
                                                prepend-icon="mdi-clipboard-text"
                                                item-text="bank_details"
                                                item-value="id">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>{{ item.name + ' ' + item.bank_details }}</v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">mdi-briefcase-account</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name + '<br>' + item.bank_details"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="3" lg="2" align-self="center" v-if="form.account_5">
                                            <v-btn color="primary" class="px-0" block depressed @click="edit_5 = false">
                                                <v-icon>mdi-pencil</v-icon>
                                                <span v-show="$vuetify.breakpoint.smAndUp">Editar</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" :sm="form.bn_5 === 'Nuestras Oficinas' ? 12 : 6">
                                            <v-text-field
                                                v-if="e1 > 5"
                                                :label="labels.name"
                                                v-model="form.name_5"
                                                type="text"
                                                name="name_5"
                                                :error-messages="errors.name_5"
                                                :rules="[rules.required('name'), rules.min('name', 3, 'El'), rules.max('name', 100, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_5"
                                                prepend-icon="mdi-account-circle"
                                                @input="clearErrors('name_5')"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-autocomplete
                                                v-if="e1 > 5"
                                                :label="labels.bank"
                                                :items="banks"
                                                name="bank_5"
                                                v-model="form.bank_5"
                                                :error-messages="errors.bank_5"
                                                :disabled="loading"
                                                :readonly="edit_5"
                                                :rules="[rules.required('bank')]"
                                                @select="clearErrors('bank_5')"
                                                prepend-icon="mdi-domain"
                                                item-text="name"
                                                item-value="code"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="form.bn_5 === 'Nuestras Oficinas'">
                                            <v-autocomplete
                                                v-if="e1 > 5"
                                                :label="labels.branch"
                                                :items="branches"
                                                name="branch_5"
                                                v-model="form.branch_5"
                                                :error-messages="errors.branch_5"
                                                :disabled="loading"
                                                :readonly="edit_5"
                                                :rules="[rules.required('branch')]"
                                                @select="clearErrors('branch_5')"
                                                prepend-icon="mdi-store"
                                                item-text="name"
                                                item-value="id"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                v-if="e1 > 5"
                                                :label="labels.td"
                                                :items="td"
                                                name="td_5"
                                                v-model="form.td_5"
                                                :error-messages="errors.td_5"
                                                :disabled="loading"
                                                :readonly="edit_5"
                                                :rules="[rules.required('td')]"
                                                @select="clearErrors('td_5')"
                                                prepend-icon="mdi-clipboard-text"
                                                item-text="name"
                                                item-value="value">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <span class="warning--text subtitle-2 text-center"
                                                                  style="width: 100%">{{ item.value }}</span>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.name }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <span class="warning--text subtitle-1 text-center" style="width: 100%">
                                                            <strong>{{ item.value }}</strong>
                                                        </span>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="destiny === 'CHL' && (form.td_5 === 'CI' || form.td_5 === 'CE')">
                                            <v-text-field
                                                v-if="e1 > 5"
                                                :label="labels.nd"
                                                v-model="form.nd_5"
                                                type="text"
                                                name="nd_5"
                                                :error-messages="errors.nd_5"
                                                :rules="[rules.required('nd'), rules.equal('nd', 10, 9, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_5"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_5')"
                                                v-mask="rut"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else-if="form.td_5 === 'PAS'">
                                            <v-text-field
                                                v-if="e1 > 5"
                                                :label="labels.nd"
                                                v-model="form.nd_5"
                                                type="text"
                                                name="nd_5"
                                                :error-messages="errors.nd_5"
                                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_5"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_5')"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else>
                                            <v-text-field
                                                v-if="e1 > 5"
                                                :label="labels.nd"
                                                v-model="form.nd_5"
                                                type="number"
                                                name="nd_5"
                                                :error-messages="errors.nd_5"
                                                :rules="[rules.required('nd'), rules.min('nd', 5, 'El'), rules.max('nd', 12, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_5"
                                                prepend-icon="mdi-ballot"
                                                @input="clearErrors('nd_5')"/>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="form.bn_5 !== 'Nuestras Oficinas'">
                                            <v-select
                                                v-if="e1 > 5"
                                                :label="labels.tc"
                                                :items="tc"
                                                name="tc_5"
                                                v-model="form.tc_5"
                                                :error-messages="errors.tc_5"
                                                :disabled="loading"
                                                :readonly="edit_5"
                                                :rules="[rules.required('tc')]"
                                                @select="clearErrors('tc_5')"
                                                prepend-icon="mdi-wallet"
                                                item-text="name"
                                                item-value="value">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <v-icon class="warning--text">mdi-account-convert</v-icon>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.name }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">mdi-account-convert</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.name"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="destiny === 'VEN' && form.bn_5 !== 'Nuestras Oficinas'">
                                            <v-text-field
                                                v-if="e1 > 5"
                                                :label="labels.nc"
                                                v-model="form.nc_5"
                                                type="tel"
                                                name="nc_5"
                                                :prefix="form.bank_5"
                                                :error-messages="errors.nc_5"
                                                :rules="[rules.required('nc'), rules.minT('nc', 19, 20, 'El'), rules.maxT('nc', 19, 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_5"
                                                prepend-icon="mdi-dialpad"
                                                @input="clearErrors('nc_5')"
                                                v-mask="mask"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" v-else-if="form.bn_5 !== 'Nuestras Oficinas'">
                                            <v-text-field
                                                v-if="e1 > 5"
                                                :label="labels.nc"
                                                v-model="form.nc_5"
                                                type="tel"
                                                name="nc_5"
                                                :error-messages="errors.nc_5"
                                                :rules="[rules.required('nc'), rules.min('nc', 5, 'El'), rules.max('nc', 20, 'El')]"
                                                :disabled="loading"
                                                :readonly="edit_5"
                                                prepend-icon="mdi-dialpad"
                                                @input="clearErrors('nc_5')"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" sm="4">
                                            <v-currency-field
                                                v-if="e1 > 5"
                                                :label="labels.Base"
                                                v-model="form.amount_5"
                                                name="amount_5"
                                                :error-messages="errors.amount_5"
                                                :rules="[rules.required('Base')]"
                                                :disabled="loading"
                                                @input="clearErrors('amount_5')"
                                                prepend-icon="mdi-cash-multiple"
                                                v-bind="currency_config"
                                            />
                                        </v-col>
                                        <template v-for="item in form.quotes_5">
                                            <v-col cols="6" sm="4" align-self="center">
                                                <v-btn
                                                    :outlined="item.value !== form.convert_5 && item.cReceiver !== form.cConvert_5"
                                                    @click="getConvert(5, item.value, item.cReceiver)"
                                                    color="primary"
                                                    :class="(item.value !== form.convert_5 && item.cReceiver !== form.cConvert_5 ? 'primary' : 'warning') + '--text'"
                                                    block
                                                    x-large>
                                                    <strong>{{ Number(item.value).toLocaleString("de-DE") + ' ' + item.cReceiver }}</strong>
                                                </v-btn>
                                            </v-col>
                                        </template>
                                        <v-col cols="12" md="10">
                                            <h4 class="text-center">
                                                <strong>Por favor revise bien la información antes de continuar</strong>
                                            </h4>
                                            <h4 class="text-center error--text" v-if="balance < 0">
                                                <strong>El monto a transferir no puede ser mayor al valor depositado</strong>
                                            </h4>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn color="tertiary" dark @click="back(6)">Atras</v-btn>
                                <v-btn class="float-right" color="primary" :disabled="balance < 0" @click="next(6)">Continuar</v-btn>
                            </v-stepper-content>

                            <v-stepper-content class="px-2 px-md-4" :step="resume - 1">
                                <v-card class="mb-12" outlined color="transparent">
                                    <v-row justify="center">
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                v-if="e1 > (resume - 2)"
                                                :label="labels.Payment"
                                                :items="payments"
                                                name="Payment"
                                                v-model="form.Payment"
                                                :error-messages="errors.Payment"
                                                :disabled="loading"
                                                :rules="[rules.req('Payment')]"
                                                @select="clearErrors('Payment')"
                                                prepend-icon="mdi-chart-donut"
                                                item-text="text"
                                                item-value="value">
                                                <template v-slot:selection="{ item }">
                                                    <v-flex>
                                                        <v-avatar size="28px" color="primary">
                                                            <v-icon class="warning--text">{{ item.icon }}</v-icon>
                                                        </v-avatar>
                                                        <span class='ml-1'>{{ item.text }}</span>
                                                    </v-flex>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="primary">
                                                        <v-icon class="warning--text">{{ item.icon }}</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.text"/>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="5" v-if="form.Payment === 'Office'">
                                            <p class="text-center mb-0">► Tendrá que realizar el pago en efectivo en las cajas de nuestras oficinas.
                                                <br>► Todas las transferencias en nuestras OFICINAS NO TIENEN COSTO.</p>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="5" v-else-if="form.Payment === 'Bank'">
                                            <v-file-input
                                                v-if="e1 > (resume - 2)"
                                                :label="labels.Voucher"
                                                v-model="form.Voucher"
                                                name="Voucher"
                                                accept="image/*"
                                                :error-messages="errors.Voucher"
                                                :rules="[rules.req('Voucher')]"
                                                :disabled="loading"
                                                prepend-icon="mdi-camera"
                                                @input="clearErrors('Voucher')"
                                                @click:clear="clearVoucher"
                                                show-size counter/>
                                        </v-col>
                                        <v-col cols="12" md="10" v-if="form.Payment === 'Bank'">
                                            <p class="text-center error--text mb-0">► Al subir el voucher acepta que esta marcado con el ASUNTO CORRESPONDIENTE.</p>
                                        </v-col>
                                        <v-col cols="6" sm="5" md="4" lg="3" v-if="imageData !== null">
                                            <img :src="imageData" width="100%" alt="voucher" style="border: 1px solid #eee; border-radius: 4px;"/>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn color="tertiary" dark @click="back(resume - 1)">Atras</v-btn>
                                <v-btn class="float-right" color="primary" :disabled="balance < 0" @click="next(resume -1)">Continuar</v-btn>
                            </v-stepper-content>

                            <v-stepper-content class="px-2 px-md-4" :step="resume">
                                <v-card class="mb-12" outlined color="transparent">
                                    <v-row justify="center">
                                        <v-col cols="12" md="6" class="text-center">
                                            <v-simple-table class="mb-8" dense>
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th colspan="2">
                                                            <h3 class="text-center">
                                                                <strong>Transferencia Nº 1</strong>
                                                            </h3>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="text-right" width="40%">Nombres</td>
                                                        <td class="text-left" width="60%">{{form.name_1}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Documento</td>
                                                        <td class="text-left">{{form.td_1}} - {{form.nd_1}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Banco</td>
                                                        <td class="text-left">{{form.bn_1}}</td>
                                                    </tr>
                                                    <tr v-if="form.bn_1 !== 'Nuestras Oficinas'">
                                                        <td class="text-right">Cuenta</td>
                                                        <td class="text-left">{{form.tc_1}}<br>{{(destiny === 'VEN' ? form.bank_1 + ' ' : '') + form.nc_1}}</td>
                                                    </tr>
                                                    <tr v-else>
                                                        <td class="text-right">Sucursal</td>
                                                        <td class="text-left">{{form.br_1}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Valor Enviado</td>
                                                        <td class="text-left">{{form.Currency === 'PEN' ? 'S/.' : '$'}} {{Number(form.amount_1).toLocaleString("de-DE")}} {{form.Currency}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Tasa de Cambio</td>
                                                        <td class="text-left">{{Number(form.fx_1).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 6})}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Costo Destino</td>
                                                        <td class="text-left">{{form.cConvert_1 === 'PEN' ? 'S/.' : '$'}} {{Number(form.fee_1).toLocaleString("de-DE")}} {{form.cConvert_1}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Valor Destino</td>
                                                        <td class="text-left">{{form.cConvert_1 === 'PEN' ? 'S/.' : '$'}} {{Number(form.convert_1).toLocaleString("de-DE")}} {{form.cConvert_1}}</td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <v-btn @click="back(3)" color="primary" style="display:inline-grid;white-space:normal;">
                                                <v-icon>mdi-pencil</v-icon> Corregir
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="6" class="text-center" v-if="form.Qty > 1">
                                            <v-simple-table class="mb-8" dense>
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th colspan="2">
                                                            <h3 class="text-center">
                                                                <strong>Transferencia Nº 2</strong>
                                                            </h3>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="text-right" width="40%">Nombres</td>
                                                        <td class="text-left" width="60%">{{form.name_2}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Documento</td>
                                                        <td class="text-left">{{form.td_2}} - {{form.nd_2}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Banco</td>
                                                        <td class="text-left">{{form.bn_2}}</td>
                                                    </tr>
                                                    <tr v-if="form.bn_2 !== 'Nuestras Oficinas'">
                                                        <td class="text-right">Cuenta</td>
                                                        <td class="text-left">{{form.tc_2}}<br>{{(destiny === 'VEN' ? form.bank_2 + ' ' : '') + form.nc_2}}</td>
                                                    </tr>
                                                    <tr v-else>
                                                        <td class="text-right">Sucursal</td>
                                                        <td class="text-left">{{form.br_2}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Valor Enviado</td>
                                                        <td class="text-left">{{form.Currency === 'PEN' ? 'S/.' : '$'}} {{Number(form.amount_2).toLocaleString("de-DE")}} {{form.Currency}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Tasa de Cambio</td>
                                                        <td class="text-left">{{Number(form.fx_2).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 6})}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Costo Destino</td>
                                                        <td class="text-left">{{form.cConvert_2 === 'PEN' ? 'S/.' : '$'}} {{Number(form.fee_2).toLocaleString("de-DE")}} {{form.cConvert_2}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Valor Destino</td>
                                                        <td class="text-left">{{form.cConvert_2 === 'PEN' ? 'S/.' : '$'}} {{Number(form.convert_2).toLocaleString("de-DE")}} {{form.cConvert_2}}</td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <v-btn @click="back(4)" color="primary" style="display:inline-grid;white-space:normal;">
                                                <v-icon>mdi-pencil</v-icon> Corregir
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="6" class="text-center" v-if="form.Qty > 2">
                                            <v-simple-table class="mb-8" dense>
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th colspan="2">
                                                            <h3 class="text-center">
                                                                <strong>Transferencia Nº 3</strong>
                                                            </h3>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="text-right" width="40%">Nombres</td>
                                                        <td class="text-left" width="60%">{{form.name_3}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Documento</td>
                                                        <td class="text-left">{{form.td_3}} - {{form.nd_3}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Banco</td>
                                                        <td class="text-left">{{form.bn_3}}</td>
                                                    </tr>
                                                    <tr v-if="form.bn_3 !== 'Nuestras Oficinas'">
                                                        <td class="text-right">Cuenta</td>
                                                        <td class="text-left">{{form.tc_3}}<br>{{(destiny === 'VEN' ? form.bank_3 + ' ' : '') + form.nc_3}}</td>
                                                    </tr>
                                                    <tr v-else>
                                                        <td class="text-right">Sucursal</td>
                                                        <td class="text-left">{{form.br_3}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Valor Enviado</td>
                                                        <td class="text-left">{{form.Currency === 'PEN' ? 'S/.' : '$'}} {{Number(form.amount_3).toLocaleString("de-DE")}} {{form.Currency}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Tasa de Cambio</td>
                                                        <td class="text-left">{{Number(form.fx_3).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 6})}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Costo Destino</td>
                                                        <td class="text-left">{{form.cConvert_3 === 'PEN' ? 'S/.' : '$'}} {{Number(form.fee_3).toLocaleString("de-DE")}} {{form.cConvert_3}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Valor Destino</td>
                                                        <td class="text-left">{{form.cConvert_3 === 'PEN' ? 'S/.' : '$'}} {{Number(form.convert_3).toLocaleString("de-DE")}} {{form.cConvert_3}}</td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <v-btn @click="back(5)" color="primary" style="display:inline-grid;white-space:normal;">
                                                <v-icon>mdi-pencil</v-icon> Corregir
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="6" class="text-center" v-if="form.Qty > 3">
                                            <v-simple-table class="mb-8" dense>
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th colspan="2">
                                                            <h3 class="text-center">
                                                                <strong>Transferencia Nº 4</strong>
                                                            </h3>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="text-right" width="40%">Nombres</td>
                                                        <td class="text-left" width="60%">{{form.name_4}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Documento</td>
                                                        <td class="text-left">{{form.td_4}} - {{form.nd_4}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Banco</td>
                                                        <td class="text-left">{{form.bn_4}}</td>
                                                    </tr>
                                                    <tr v-if="form.bn_4 !== 'Nuestras Oficinas'">
                                                        <td class="text-right">Cuenta</td>
                                                        <td class="text-left">{{form.tc_4}}<br>{{(destiny === 'VEN' ? form.bank_4 + ' ' : '') + form.nc_4}}</td>
                                                    </tr>
                                                    <tr v-else>
                                                        <td class="text-right">Sucursal</td>
                                                        <td class="text-left">{{form.br_4}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Valor Enviado</td>
                                                        <td class="text-left">{{form.Currency === 'PEN' ? 'S/.' : '$'}} {{Number(form.amount_4).toLocaleString("de-DE")}} {{form.Currency}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Tasa de Cambio</td>
                                                        <td class="text-left">{{Number(form.fx_4).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 6})}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Costo Destino</td>
                                                        <td class="text-left">{{form.cConvert_4 === 'PEN' ? 'S/.' : '$'}} {{Number(form.fee_4).toLocaleString("de-DE")}} {{form.cConvert_4}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Valor Destino</td>
                                                        <td class="text-left">{{form.cConvert_4 === 'PEN' ? 'S/.' : '$'}} {{Number(form.convert_4).toLocaleString("de-DE")}} {{form.cConvert_4}}</td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <v-btn @click="back(6)" color="primary" style="display:inline-grid;white-space:normal;">
                                                <v-icon>mdi-pencil</v-icon> Corregir
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="6" class="text-center" v-if="form.Qty > 4">
                                            <v-simple-table class="mb-8" dense>
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th colspan="2">
                                                            <h3 class="text-center">
                                                                <strong>Transferencia Nº 5</strong>
                                                            </h3>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="text-right" width="40%">Nombres</td>
                                                        <td class="text-left" width="60%">{{form.name_5}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Documento</td>
                                                        <td class="text-left">{{form.td_5}} - {{form.nd_5}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Banco</td>
                                                        <td class="text-left">{{form.bn_5}}</td>
                                                    </tr>
                                                    <tr v-if="form.bn_5 !== 'Nuestras Oficinas'">
                                                        <td class="text-right">Cuenta</td>
                                                        <td class="text-left">{{form.tc_5}}<br>{{(destiny === 'VEN' ? form.bank_5 + ' ' : '') + form.nc_5}}</td>
                                                    </tr>
                                                    <tr v-else>
                                                        <td class="text-right">Sucursal</td>
                                                        <td class="text-left">{{form.br_5}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Valor Enviado</td>
                                                        <td class="text-left">{{form.Currency === 'PEN' ? 'S/.' : '$'}} {{Number(form.amount_5).toLocaleString("de-DE")}} {{form.Currency}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Tasa de Cambio</td>
                                                        <td class="text-left">{{Number(form.fx_5).toLocaleString("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 6})}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Costo Destino</td>
                                                        <td class="text-left">{{form.cConvert_5 === 'PEN' ? 'S/.' : '$'}} {{Number(form.fee_5).toLocaleString("de-DE")}} {{form.cConvert_5}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">Valor Destino</td>
                                                        <td class="text-left">{{form.cConvert_5 === 'PEN' ? 'S/.' : '$'}} {{Number(form.convert_5).toLocaleString("de-DE")}} {{form.cConvert_5}}</td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <v-btn @click="back(7)" color="primary" style="display:inline-grid;white-space:normal;">
                                                <v-icon>mdi-pencil</v-icon> Corregir
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="8" sm="6" lg="4">
                                            <h3 class="text-center primary warning--text py-1" style="width: 100%; border-radius: 6px;">
                                                <strong>Costo de Envío: {{Number(form.Fee).toLocaleString("de-DE")}} {{form.Currency}}</strong>
                                            </h3>
                                        </v-col>
                                        <v-col cols="12" md="10">
                                            <h3 class="text-center">
                                                <strong>Por favor revise bien la información antes de finalizar</strong>
                                            </h3>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-btn color="tertiary" dark @click="back(resume)">Atras</v-btn>
                                <v-btn class="float-right" color="success" :loading="loading" :disabled="loading || !valid" @click="submit">Enviar</v-btn>
                            </v-stepper-content>
                        </v-form>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import Form from "../../helpers/form";
import { mask }from 'vue-the-mask';

export default {
    name: "FormReceiver",
    mixins: [ Form ],
    props: [ 'user', 'destiny' ],
    directives: { mask },
    data: () => ({
        e1: 1,
        skill: 25,
        payments: [],
        cs: [],
        cr: [],
        rates: [],
        accounts: [],
        banks: [],
        branches: [],
        offices: [],
        fees: [],
        limits: [],
        labels: {
            Payment: 'Forma de Pago',
            Voucher: 'Foto del Voucher',
            Amount: 'Valor Depositado',
            Currency: 'Moneda del Deposito',
            Qty: 'Transferencias a Realizar',
            account: 'Cuenta Bancaria',
            name: 'Nombre del Beneficiario',
            td: 'Tipo de Documento',
            nd: 'Número de Documento',
            bank: 'Banco del Beneficiario',
            branch: 'Oficina de Pago',
            tc: 'Tipo de Cuenta',
            nc: 'Número de Cuenta',
            Base: 'Monto a Transferir'
        },
        td: [],
        tc: [],
        edit_1: false,
        edit_2: false,
        edit_3: false,
        edit_4: false,
        edit_5: false,
        form: {
            Currency: null,
            Amount: null,
            Qty: null,
            Fee: null,
            Payment: null,
            Voucher: null,

            account_1: null,
            name_1: null,
            td_1: null,
            nd_1: null,
            bank_1: null,
            branch_1: null,
            bn_1: null,
            br_1: null,
            tc_1: null,
            nc_1: null,
            amount_1: null,
            quotes_1: [],
            fee_1: null,
            fx_1: null,
            convert_1: null,
            cConvert_1: null,

            account_2: null,
            name_2: null,
            td_2: null,
            nd_2: null,
            bank_2: null,
            branch_2: null,
            bn_2: null,
            br_2: null,
            tc_2: null,
            nc_2: null,
            amount_2: null,
            quotes_2: [],
            fee_2: null,
            fx_2: null,
            convert_2: null,
            cConvert_2: null,

            account_3: null,
            name_3: null,
            td_3: null,
            nd_3: null,
            bank_3: null,
            branch_3: null,
            bn_3: null,
            br_3: null,
            tc_3: null,
            nc_3: null,
            amount_3: null,
            quotes_3: [],
            fee_3: null,
            fx_3: null,
            convert_3: null,
            cConvert_3: null,

            account_4: null,
            name_4: null,
            td_4: null,
            nd_4: null,
            bank_4: null,
            branch_4: null,
            bn_4: null,
            br_4: null,
            tc_4: null,
            nc_4: null,
            amount_4: null,
            quotes_4: [],
            fee_4: null,
            fx_4: null,
            convert_4: null,
            cConvert_4: null,

            account_5: null,
            name_5: null,
            td_5: null,
            nd_5: null,
            bank_5: null,
            branch_5: null,
            bn_5: null,
            br_5: null,
            tc_5: null,
            nc_5: null,
            amount_5: null,
            quotes_5: [],
            fee_5: null,
            fx_5: null,
            convert_5: null,
            cConvert_5: null
        },
        min: null,
        max: null,
        QtyMax: 5,
        imageData: null,
        currency_config: {
            locale: 'de-DE',
            decimalLength: 2,
            autoDecimalMode: false,
            min: null,
            max: null,
            defaultValue: 0,
            valueAsInteger: false,
            allowNegative: false
        },
        resume: 4,
        mask: '#### #### #### ####',
        rut: '########-X'
    }),
    mounted () {
        this.getIni();
        this.getTypeDoc();
        this.getTypeAcc();
    },
    watch: {
        destiny() {
            this.reload();
        },
        'user.country'() {
            this.reload();
        },
        'form.Payment'() {
            if (this.form.Payment) {
                if (this.form.Payment !== 'Bank') {
                    this.form.Voucher = null;
                    this.imageData = null;
                }
            }
        },
        'form.Voucher'() {
            if (this.form.Voucher) {
                this.getVoucher();
            }
        },
        'form.Amount'() {
            if (this.form.Amount) {
                this.getParams();
            }
        },
        'form.Currency'() {
            if (this.form.Currency) {
                this.getParams();
                if (this.form.Amount) {
                    this.$refs.form.resetValidation();
                }
            }
        },
        'form.Qty'() {
            this.getQty();
        },
        'form.account_1'() {
            if (this.form.account_1) {
                this.account(1);
                this.edit_1 = true;
            } else {
                this.form.account_1 = null;
                this.form.name_1 = null;
                this.form.td_1 = null;
                this.form.nd_1 = null;
                this.form.bank_1 = null;
                this.form.branch_1 = null;
                this.form.tc_1 = null;
                this.form.nc_1 = null;
                this.edit_1 = false;
                this.$refs.form.resetValidation();
            }
        },
        'form.account_2'() {
            if (this.form.account_2) {
                this.account(2);
                this.edit_2 = true;
            } else {
                this.form.account_2 = null;
                this.form.name_2 = null;
                this.form.td_2 = null;
                this.form.nd_2 = null;
                this.form.bank_2 = null;
                this.form.branch_2 = null;
                this.form.tc_2 = null;
                this.form.nc_2 = null;
                this.edit_2 = false;
                this.$refs.form.resetValidation();
            }
        },
        'form.account_3'() {
            if (this.form.account_3) {
                this.account(3);
                this.edit_3 = true;
            } else {
                this.form.account_3 = null;
                this.form.name_3 = null;
                this.form.td_3 = null;
                this.form.nd_3 = null;
                this.form.bank_3 = null;
                this.form.branch_3 = null;
                this.form.tc_3 = null;
                this.form.nc_3 = null;
                this.edit_3 = false;
                this.$refs.form.resetValidation();
            }
        },
        'form.account_4'() {
            if (this.form.account_4) {
                this.account(4);
                this.edit_4 = true;
            } else {
                this.form.account_4 = null;
                this.form.name_4 = null;
                this.form.td_4 = null;
                this.form.nd_4 = null;
                this.form.bank_4 = null;
                this.form.branch_4 = null;
                this.form.tc_4 = null;
                this.form.nc_4 = null;
                this.edit_4 = false;
                this.$refs.form.resetValidation();
            }
        },
        'form.account_5'() {
            if (this.form.account_5) {
                this.account(5);
                this.edit_5 = true;
            } else {
                this.form.account_5 = null;
                this.form.name_5 = null;
                this.form.td_5 = null;
                this.form.nd_5 = null;
                this.form.bank_5 = null;
                this.form.branch_5 = null;
                this.form.tc_5 = null;
                this.form.nc_5 = null;
                this.edit_5 = false;
                this.$refs.form.resetValidation();
            }
        },
        'form.nd_1'() {
            if (this.form.td_1 === 'PAS') {
                this.form.nd_1 = (this.form.nd_1).toUpperCase();
            }
        },
        'form.nd_2'() {
            if (this.form.td_2 === 'PAS') {
                this.form.nd_2 = (this.form.nd_2).toUpperCase();
            }
        },
        'form.nd_3'() {
            if (this.form.td_3 === 'PAS') {
                this.form.nd_3 = (this.form.nd_3).toUpperCase();
            }
        },
        'form.nd_4'() {
            if (this.form.td_4 === 'PAS') {
                this.form.nd_4 = (this.form.nd_4).toUpperCase();
            }
        },
        'form.nd_5'() {
            if (this.form.td_5 === 'PAS') {
                this.form.nd_5 = (this.form.nd_5).toUpperCase();
            }
        },
        'form.bank_1'() {
            if (this.form.bank_1) {
                this.getBank(1);
                this.calculator(1);
            } else {
                this.form.bn_1 = null;
            }
        },
        'form.bank_2'() {
            if (this.form.bank_2) {
                this.getBank(2);
                this.calculator(2);
            } else {
                this.form.bn_2 = null;
            }
        },
        'form.bank_3'() {
            if (this.form.bank_3) {
                this.getBank(3);
                this.calculator(3);
            } else {
                this.form.bn_3 = null;
            }
        },
        'form.bank_4'() {
            if (this.form.bank_4) {
                this.getBank(4);
                this.calculator(4);
            } else {
                this.form.bn_4 = null;
            }
        },
        'form.bank_5'() {
            if (this.form.bank_5) {
                this.getBank(5);
                this.calculator(5);
            } else {
                this.form.bn_5 = null;
            }
        },
        'form.branch_1'() {
            if (this.form.branch_1) {
                this.getBranch(1);
                this.calculator(1);
            } else {
                this.form.br_1 = null;
            }
        },
        'form.branch_2'() {
            if (this.form.branch_2) {
                this.getBranch(2);
                this.calculator(2);
            } else {
                this.form.br_2 = null;
            }
        },
        'form.branch_3'() {
            if (this.form.branch_3) {
                this.getBranch(3);
                this.calculator(3);
            } else {
                this.form.br_3 = null;
            }
        },
        'form.branch_4'() {
            if (this.form.branch_4) {
                this.getBranch(4);
                this.calculator(4);
            } else {
                this.form.br_4 = null;
            }
        },
        'form.branch_5'() {
            if (this.form.branch_5) {
                this.getBranch(5);
                this.calculator(5);
            } else {
                this.form.br_5 = null;
            }
        },
        'form.tc_1'() {
            if (this.form.tc_1) {
                this.calculator(1);
            }
        },
        'form.tc_2'() {
            if (this.form.tc_2) {
                this.calculator(2);
            }
        },
        'form.tc_3'() {
            if (this.form.tc_3) {
                this.calculator(3);
            }
        },
        'form.tc_4'() {
            if (this.form.tc_4) {
                this.calculator(4);
            }
        },
        'form.tc_5'() {
            if (this.form.tc_5) {
                this.calculator(5);
            }
        },
        'form.amount_1'() {
            if (this.form.amount_1) {
                this.calculator(1);
            } else {
                this.form.convert_1 = null;
            }
        },
        'form.amount_2'() {
            if (this.form.amount_2) {
                this.calculator(2);
            } else {
                this.form.convert_2 = null;
            }
        },
        'form.amount_3'() {
            if (this.form.amount_3) {
                this.calculator(3);
            } else {
                this.form.convert_3 = null;
            }
        },
        'form.amount_4'() {
            if (this.form.amount_4) {
                this.calculator(4);
            } else {
                this.form.convert_4 = null;
            }
        },
        'form.amount_5'() {
            if (this.form.amount_5) {
                this.calculator(5);
            } else {
                this.form.convert_5 = null;
            }
        },
    },
    computed: {
        balance() {
            return this.form.Amount - this.form.Fee - this.form.amount_1 - this.form.amount_2 - this.form.amount_3 - this.form.amount_4 - this.form.amount_5;
        }
    },
    methods: {
        decrement () {
            this.form.Qty--
        },
        increment () {
            this.form.Qty++
        },
        back(n) {
            this.e1 = n - 1;
            this.skill = (100 * (n - 1)) / this.resume;
        },
        next(n) {
            if (this.$refs.form.validate()) {
                this.e1 = n + 1;
                this.skill = (100 * (n + 1)) / this.resume;
            }
            if (n === 1) {
                this.getParams();
            }
        },
        reload() {
            this.e1 = 1;
            this.skill = 25;
            this.resume = 4;
            this.min = null;
            this.imageData = null;
            this.form = {
                Currency: null,
                Amount: null,
                Qty: null,
                Fee: null,
                Payment: null,
                Voucher: null,

                account_1: null,
                name_1: null,
                td_1: null,
                nd_1: null,
                bank_1: null,
                branch_1: null,
                bn_1: null,
                br_1: null,
                tc_1: null,
                nc_1: null,
                amount_1: null,
                quotes_1: [],
                fee_1: null,
                fx_1: null,
                convert_1: null,
                cConvert_1: null,

                account_2: null,
                name_2: null,
                td_2: null,
                nd_2: null,
                bank_2: null,
                branch_2: null,
                bn_2: null,
                br_2: null,
                tc_2: null,
                nc_2: null,
                amount_2: null,
                quotes_2: [],
                fee_2: null,
                fx_2: null,
                convert_2: null,
                cConvert_2: null,

                account_3: null,
                name_3: null,
                td_3: null,
                nd_3: null,
                bank_3: null,
                branch_3: null,
                bn_3: null,
                br_3: null,
                tc_3: null,
                nc_3: null,
                amount_3: null,
                quotes_3: [],
                fee_3: null,
                fx_3: null,
                convert_3: null,
                cConvert_3: null,

                account_4: null,
                name_4: null,
                td_4: null,
                nd_4: null,
                bank_4: null,
                branch_4: null,
                bn_4: null,
                br_4: null,
                tc_4: null,
                nc_4: null,
                amount_4: null,
                quotes_4: [],
                fee_4: null,
                fx_4: null,
                convert_4: null,
                cConvert_4: null,

                account_5: null,
                name_5: null,
                td_5: null,
                nd_5: null,
                bank_5: null,
                branch_5: null,
                bn_5: null,
                br_5: null,
                tc_5: null,
                nc_5: null,
                amount_5: null,
                quotes_5: [],
                fee_5: null,
                fx_5: null,
                convert_5: null,
                cConvert_5: null
            };
            this.$refs.form.reset();
            this.getIni();
            this.getTypeDoc();
            this.getTypeAcc();
        },
        getIni() {
            this.loading = true;

            if (this.$props.user.country) {
                this.getPayments(this.$props.user.country);
            } else {
                axios.get('/api/profile').then((res) => {
                    this.getPayments(res.data.user.country);
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                });
            }
        },
        getTypeDoc() {
            let c = this.destiny;
            if (c === 'MEX' || c === 'USA') {
                this.td = [
                    { name: 'Licencia de Conducir', value: 'LIC' },
                    { name: 'Pasaporte', value: 'PAS' }
                ]
            } else if (c === 'PER') {
                this.td = [
                    { name: 'Documento Nacional de Identidad', value: 'DNI' },
                    { name: 'Carnet de Extranjeria', value: 'CE' },
                    { name: 'Permiso Temporal de Permanencia', value: 'PTP' },
                    { name: 'Pasaporte', value: 'PAS' }
                ]
            } else if (c === 'COL') {
                this.td = [
                    { name: 'Cedula de Ciudadania', value: 'CC' },
                    { name: 'Cedula de Extranjeria', value: 'CE' },
                    { name: 'Número de Identificación Tributaria', value: 'NIT' },
                    { name: 'Permiso por Protección Temporal', value: 'PPT' },
                    { name: 'Pasaporte', value: 'PAS' }
                ]
            } else if (c === 'VEN') {
                this.td = [
                    { name: 'Cedula de Identidad', value: 'V' },
                    { name: 'Carnet de Extranjeria', value: 'E' },
                    { name: 'RIF', value: 'J' },
                    { name: 'Gubernamental', value: 'G' },
                    { name: 'Firma Personal', value: 'M' },
                    { name: 'Pasaporte', value: 'PAS' }
                ]
            } else if (c === 'CHL') {
                this.td = [
                    {name: 'Rol Único Tributario', value: 'RUT'},
                    {name: 'Rol Único Nacional', value: 'RUN'},
                    {name: 'Cedula de Identidad', value: 'CI'},
                    {name: 'Cedula de Extranjeria', value: 'CE'},
                    {name: 'Licencia de Conducir', value: 'LIC'},
                    {name: 'Pasaporte', value: 'PAS'}
                ]
            } else {
                this.td = [
                    { name: 'Cedula de Identidad', value: 'CI' },
                    { name: 'Cedula de Extranjeria', value: 'CE' },
                    { name: 'Licencia de Conducir', value: 'LIC' },
                    { name: 'Pasaporte', value: 'PAS' }
                ]
            }
        },
        getTypeAcc() {
            let c = this.destiny;
            if (c === 'CHL') {
                this.tc = [
                    { name: 'Cuenta de Ahorros', value: 'Ahorros' },
                    { name: 'Cuenta Corriente', value: 'Corriente' },
                    { name: 'Cuenta Vista', value: 'Vista' },
                    { name: 'Cuenta RUT', value: 'RUT' }
                ]
            } else {
                this.tc = [
                    { name: 'Cuenta de Ahorros', value: 'Ahorros' },
                    { name: 'Cuenta Corriente', value: 'Corriente' }
                ]
            }
        },
        getBanks() {
            let d = this.destiny;
            axios.get('/api/banks/' + d).then((res) => {
                this.banks = res.data.banks;
                this.branches = res.data.branches;
            }).catch(err => {
                this.handleErrors(err.response.data.errors);
            });
        },
        getPayments(c) {
            axios.get('/api/offices').then((res) => {
                this.offices = res.data.offices;
                this.payments = [
                    { text: 'Deposito/Transferencia', value: 'Bank', icon: 'mdi-bank-transfer' }
                ];
                if (res.data.c > 0) {
                    this.payments.push({ text: 'Efectivo', value: 'Office', icon: 'mdi-currency-usd' });
                }
            }).catch(err => {
                this.handleErrors(err.response.data.errors);
            });
            this.getCurrencies(c);
        },
        getMyAccounts() {
            let d = this.destiny;
            axios.get('/api/receivers/v/' + d).then((res) => {
                this.accounts = res.data.items;
            }).catch(err => {
                this.handleErrors(err.response.data.errors);
            });
        },
        getBank(n) {
            const banks = this.banks;

            if (n === 1) {
                for (let i = 0; i < banks.length; i++) {
                    if (banks[i].code === this.form.bank_1) {
                        if (banks[i].name === 'Nuestras Oficinas') {
                            this.form.tc_1 = null;
                            this.form.nc_1 = null;
                        } else {
                            this.form.branch_1 = null;
                        }
                        this.form.bn_1 = banks[i].name;
                    }
                }
            } else if (n === 2) {
                for (let i = 0; i < banks.length; i++) {
                    if (banks[i].code === this.form.bank_2) {
                        if (banks[i].name === 'Nuestras Oficinas') {
                            this.form.tc_2 = null;
                            this.form.nc_2 = null;
                        } else {
                            this.form.branch_2 = null;
                        }
                        this.form.bn_2 = banks[i].name;
                    }
                }
            } else if (n === 3) {
                for (let i = 0; i < banks.length; i++) {
                    if (banks[i].code === this.form.bank_3) {
                        if (banks[i].name === 'Nuestras Oficinas') {
                            this.form.tc_3 = null;
                            this.form.nc_3 = null;
                        } else {
                            this.form.branch_3 = null;
                        }
                        this.form.bn_3 = banks[i].name;
                    }
                }
            } else if (n === 4) {
                for (let i = 0; i < banks.length; i++) {
                    if (banks[i].code === this.form.bank_4) {
                        if (banks[i].name === 'Nuestras Oficinas') {
                            this.form.tc_4 = null;
                            this.form.nc_4 = null;
                        } else {
                            this.form.branch_4 = null;
                        }
                        this.form.bn_4 = banks[i].name;
                    }
                }
            } else if (n === 5) {
                for (let i = 0; i < banks.length; i++) {
                    if (banks[i].code === this.form.bank_5) {
                        if (banks[i].name === 'Nuestras Oficinas') {
                            this.form.tc_5 = null;
                            this.form.nc_5 = null;
                        } else {
                            this.form.branch_5 = null;
                        }
                        this.form.bn_5 = banks[i].name;
                    }
                }
            }
        },
        getBranch(n) {
            const branches = this.branches;

            if (n === 1) {
                for (let i = 0; i < branches.length; i++) {
                    if (branches[i].id === this.form.branch_1) {
                        this.form.br_1 = branches[i].name;
                    }
                }
            } else if (n === 2) {
                for (let i = 0; i < branches.length; i++) {
                    if (branches[i].id === this.form.branch_2) {
                        this.form.br_2 = branches[i].name;
                    }
                }
            } else if (n === 3) {
                for (let i = 0; i < branches.length; i++) {
                    if (branches[i].id === this.form.branch_3) {
                        this.form.br_3 = branches[i].name;
                    }
                }
            } else if (n === 4) {
                for (let i = 0; i < branches.length; i++) {
                    if (branches[i].id === this.form.branch_4) {
                        this.form.br_4 = branches[i].name;
                    }
                }
            } else if (n === 5) {
                for (let i = 0; i < branches.length; i++) {
                    if (branches[i].id === this.form.branch_5) {
                        this.form.br_5 = branches[i].name;
                    }
                }
            }
        },
        getCurrencies(country) {
            this.loading = true;
            let d = this.destiny;
            axios.get('/api/currencies/' + country + '/' + d).then((res) => {
                this.cs = res.data.cs;
                this.cr = res.data.cr;
                this.fees = res.data.fees;
                this.limits = res.data.limits;
                this.rates = res.data.rates;
                this.form.Currency = this.cs[0].code;
                if (this.accounts) {
                    this.getMyAccounts();
                }
                if (this.banks) {
                    this.getBanks();
                }
            }).catch(err => {
                this.handleErrors(err.response.data.errors);
            }).then(() => {
                this.loading = false;
            });
        },
        getParams() {
            let fee = 0;
            let cs = this.cs;
            let fees = this.fees;
            let limits = this.limits;
            let mins = limits['min'];
            let maxs = limits['max'];
            let currency = this.form.Currency;

            for (let i = 0; i < cs.length; i++) {
                if (cs[i].code === currency) {
                    this.currency_config.decimalLength = cs[i].decimals;
                }
            }

            for (let i = 0; i < mins.length; i++) {
                if (mins[i].data.currency === currency) {
                    this.min = parseInt(mins[i].data.value);
                }
            }

            for (let i = 0; i < maxs.length; i++) {
                if (maxs[i].data.currency === currency) {
                    this.max = parseInt(maxs[i].data.value);
                }
            }

            if (this.form.Amount !== null && this.form.Amount > 0) {
                for (let i = 0; i < fees.length; i++) {
                    if (fees[i].category === 'In') {
                        if (this.form.Amount >= fees[i].range_min && this.form.Amount < fees[i].range_max) {
                            if (fees[i].currency === currency || (!fee && fees[i].currency === null)) {
                                if (fees[i].type === '%') {
                                    fee = parseFloat(this.form.Amount * (fees[i].value / 100)).toFixed(this.currency_config.decimalLength);
                                } else {
                                    fee = parseFloat(fees[i].value);
                                }
                            }
                        }
                    }
                }
                this.form.Fee = fee;
            }

            if (this.form.Qty) {
                this.getQty();
            }
        },
        getQty() {
            this.resume = this.form.Qty + 3;
            if (this.form.Qty === 1) {
                this.form.amount_1 = this.form.Amount - this.form.Fee;
            } else {
                this.form.amount_1 = null;
            }
            this.form.amount_2 = null;
            this.form.amount_3 = null;
            this.form.amount_4 = null;
            this.form.amount_5 = null;
            this.skill = (100 * this.e1) / this.resume;
        },
        getVoucher() {
            const input = this.form.Voucher;

            if (input) {
                const reader = new FileReader;
                reader.onload = e => {
                    this.imageData = e.target.result;
                };
                reader.readAsDataURL(input);
            }
        },
        clearVoucher() {
            this.form.Voucher = null;
            this.imageData = null;
        },
        account(n) {
            const accounts = this.accounts;

            if (n === 1) {
                for (let i = 0; i < accounts.length; i++) {
                    if (accounts[i].id === this.form.account_1) {
                        this.form.name_1 = accounts[i].name;
                        this.form.td_1 = accounts[i].td;
                        this.form.nd_1 = accounts[i].nd;
                        this.form.bank_1 = accounts[i].bank_code;
                        this.form.branch_1 = accounts[i].branch;
                        this.form.bn_1 = accounts[i].bank_name;
                        this.form.br_1 = accounts[i].branch_name;
                        this.form.tc_1 = accounts[i].tc;
                        this.form.nc_1 = this.destiny === 'VEN' && accounts[i].nc ? (accounts[i].nc).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : accounts[i].nc;
                    }
                }
            } else if (n === 2) {
                for (let i = 0; i < accounts.length; i++) {
                    if (accounts[i].id === this.form.account_2) {
                        this.form.name_2 = accounts[i].name;
                        this.form.td_2 = accounts[i].td;
                        this.form.nd_2 = accounts[i].nd;
                        this.form.bank_2 = accounts[i].bank_code;
                        this.form.branch_2 = accounts[i].branch;
                        this.form.bn_2 = accounts[i].bank_name;
                        this.form.br_2 = accounts[i].branch_name;
                        this.form.tc_2 = accounts[i].tc;
                        this.form.nc_2 = this.destiny === 'VEN' && accounts[i].nc ? (accounts[i].nc).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : accounts[i].nc;
                    }
                }
            } else if (n === 3) {
                for (let i = 0; i < accounts.length; i++) {
                    if (accounts[i].id === this.form.account_3) {
                        this.form.name_3 = accounts[i].name;
                        this.form.td_3 = accounts[i].td;
                        this.form.nd_3 = accounts[i].nd;
                        this.form.bank_3 = accounts[i].bank_code;
                        this.form.branch_3 = accounts[i].branch;
                        this.form.bn_3 = accounts[i].bank_name;
                        this.form.br_3 = accounts[i].branch_name;
                        this.form.tc_3 = accounts[i].tc;
                        this.form.nc_3 = this.destiny === 'VEN' && accounts[i].nc ? (accounts[i].nc).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : accounts[i].nc;
                    }
                }
            } else if (n === 4) {
                for (let i = 0; i < accounts.length; i++) {
                    if (accounts[i].id === this.form.account_4) {
                        this.form.name_4 = accounts[i].name;
                        this.form.td_4 = accounts[i].td;
                        this.form.nd_4 = accounts[i].nd;
                        this.form.bank_4 = accounts[i].bank_code;
                        this.form.branch_4 = accounts[i].branch;
                        this.form.bn_4 = accounts[i].bank_name;
                        this.form.br_4 = accounts[i].branch_name;
                        this.form.tc_4 = accounts[i].tc;
                        this.form.nc_4 = this.destiny === 'VEN' && accounts[i].nc ? (accounts[i].nc).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : accounts[i].nc;
                    }
                }
            } else if (n === 5) {
                for (let i = 0; i < accounts.length; i++) {
                    if (accounts[i].id === this.form.account_5) {
                        this.form.name_5 = accounts[i].name;
                        this.form.td_5 = accounts[i].td;
                        this.form.nd_5 = accounts[i].nd;
                        this.form.bank_5 = accounts[i].bank_code;
                        this.form.branch_5 = accounts[i].branch;
                        this.form.bn_5 = accounts[i].bank_name;
                        this.form.br_5 = accounts[i].branch_name;
                        this.form.tc_5 = accounts[i].tc;
                        this.form.nc_5 = this.destiny === 'VEN' && accounts[i].nc ? (accounts[i].nc).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : accounts[i].nc;
                    }
                }
            }
        },
        getConvert(n, value, currency) {
          if (n === 1) {
            this.form.convert_1 = value;
            this.form.cConvert_1 = currency;
          } else if (n === 2) {
            this.form.convert_2 = value;
            this.form.cConvert_2 = currency;
          } else if (n === 3) {
            this.form.convert_3 = value;
            this.form.cConvert_3 = currency;
          } else if (n === 4) {
            this.form.convert_4 = value;
            this.form.cConvert_4 = currency;
          } else if (n === 5) {
            this.form.convert_5 = value;
            this.form.cConvert_5 = currency;
          }
        },
        calculator(n) {
            let br = 0;
            let bc = "";
            let quotes = [];
            let cr = this.cr;
            let fees = this.fees;
            let rates = this.rates;
            let branches = this.branches;
            let bank = this.form.bank_1;
            let branch = this.form.branch_1;
            let amount = this.form.amount_1;
            let currency = this.form.Currency;
            if (n === 2) {
                bank = this.form.bank_2;
                branch = this.form.branch_2;
                amount = this.form.amount_2;
            } else if (n === 3) {
                bank = this.form.bank_3;
                branch = this.form.branch_3;
                amount = this.form.amount_3;
            } else if (n === 4) {
                bank = this.form.bank_4;
                branch = this.form.branch_4;
                amount = this.form.amount_4;
            } else if (n === 5) {
                bank = this.form.bank_5;
                branch = this.form.branch_5;
                amount = this.form.amount_5;
            }

            if (branch !== null) {
                for (let i = 0; i < branches.length; i++) {
                    if (branches[i].id === branch) {
                        bc = branches[i].currencies;
                    }
                }
            }

            for (let i = 0; i < rates.length; i++) {
                let fee = 0;
                let result = 0;
                if (rates[i].currency_send === currency && (branch === null || bc.includes(rates[i].currency_receiver)) &&
                    (rates[i].bank_receiver === bank || (br === 0 && rates[i].bank_receiver === null))) {
                    let dd = 2;
                    for (let e = 0; e < cr.length; e++) {
                        if (cr[e].code === rates[i].currency_receiver) {
                            dd = cr[e].decimals;
                        }
                    }
                    if (bank !== null && rates[i].bank_receiver === bank) {
                        br++;
                        for (let i = 0; i < quotes.length; i++) {
                            if (quotes[i].bank === null) {
                                quotes.splice((i-1), 1);
                            }
                        }
                    }
                    if (rates[i].type === 'sell') {
                        result = parseFloat(amount / rates[i].value).toFixed(dd);
                    } else if (rates[i].type === 'buy') {
                        result = parseFloat(amount * rates[i].value).toFixed(dd);
                    }

                    for (let e = 0; e < fees.length; e++) {
                        if (fees[e].category === 'Out') {
                            if (result >= fees[e].range_min && result < fees[e].range_max) {
                                if (fees[e].currency === rates[i].currency_receiver || (!fee && fees[e].currency === null)) {
                                    if (fees[e].type === '%') {
                                        fee = parseFloat(result * (fees[e].value / 100)).toFixed(dd);
                                    } else {
                                        fee = fees[e].value;
                                    }
                                }
                            }
                        }
                    }

                    quotes.push({
                        "value": result - fee,
                        "cSend": rates[i].currency_send,
                        "cReceiver": rates[i].currency_receiver,
                        "fee": fee,
                        "fx": rates[i].value,
                        "bank": rates[i].bank_receiver,
                        "rate": rates[i]
                    });
                }
            }

            if (quotes.length > 0) {
                if (n === 1) {
                    this.form.quotes_1 = quotes;
                    this.form.fx_1 = quotes[0].fx;
                    this.form.fee_1 = quotes[0].fee;
                    this.form.convert_1 = quotes[0].value;
                    this.form.cConvert_1 = quotes[0].cReceiver;
                } else if (n === 2) {
                    this.form.quotes_2 = quotes;
                    this.form.fx_2 = quotes[0].fx;
                    this.form.fee_2 = quotes[0].fee;
                    this.form.convert_2 = quotes[0].value;
                    this.form.cConvert_2 = quotes[0].cReceiver;
                } else if (n === 3) {
                    this.form.quotes_3 = quotes;
                    this.form.fx_3 = quotes[0].fx;
                    this.form.fee_3 = quotes[0].fee;
                    this.form.convert_3 = quotes[0].value;
                    this.form.cConvert_3 = quotes[0].cReceiver;
                } else if (n === 4) {
                    this.form.quotes_4 = quotes;
                    this.form.fx_4 = quotes[0].fx;
                    this.form.fee_4 = quotes[0].fee;
                    this.form.convert_4 = quotes[0].value;
                    this.form.cConvert_4 = quotes[0].cReceiver;
                } else if (n === 5) {
                    this.form.quotes_5 = quotes;
                    this.form.fx_5 = quotes[0].fx;
                    this.form.fee_5 = quotes[0].fee;
                    this.form.convert_5 = quotes[0].value;
                    this.form.cConvert_5 = quotes[0].cReceiver;
                }
            }
        },
        submit() {
            const amount = this.form.Amount;
            const v = Number(this.form.Fee + this.form.amount_1 + this.form.amount_2 + this.form.amount_3 + this.form.amount_4 + this.form.amount_5);

            if (parseFloat(amount - v) === 0) {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    let formData = new FormData();

                    formData.append('country', this.$props.user.country);
                    formData.append('destiny', this.destiny);

                    formData.append('payment',  this.form.Payment);
                    formData.append('voucher', this.form.Voucher);
                    formData.append('amount',  this.form.Amount);
                    formData.append('currency',  this.form.Currency);
                    formData.append('Qty',  this.form.Qty);
                    formData.append('fee',  this.form.Fee);

                    formData.append('account_1',  this.form.account_1 ? this.form.account_1 : 0);
                    formData.append('name_1',  this.form.name_1);
                    formData.append('td_1',  this.form.td_1);
                    formData.append('nd_1',  this.form.nd_1);
                    formData.append('bank_1',  this.form.bank_1);
                    formData.append('branch_1',  this.form.bn_1 !== 'Nuestras Oficinas' ? null : this.form.branch_1);
                    formData.append('tc_1',  this.form.bn_1 === 'Nuestras Oficinas' ? null : this.form.tc_1);
                    formData.append('nc_1',  this.form.bn_1 === 'Nuestras Oficinas' ? null : (this.form.nc_1).replace(/ /g, ''));
                    formData.append('amount_1',  this.form.amount_1);
                    formData.append('quotes_1',  JSON.stringify(this.form.quotes_1));
                    formData.append('fee_1',  this.form.fee_1);
                    formData.append('fx_1',  this.form.fx_1);
                    formData.append('convert_1',  this.form.convert_1);
                    formData.append('cConvert_1',  this.form.cConvert_1);

                    if (this.form.Qty > 1) {
                        formData.append('account_2', this.form.account_2 ? this.form.account_2 : 0);
                        formData.append('name_2', this.form.name_2);
                        formData.append('td_2', this.form.td_2);
                        formData.append('nd_2', this.form.nd_2);
                        formData.append('bank_2', this.form.bank_2);
                        formData.append('branch_2',  this.form.bn_2 !== 'Nuestras Oficinas' ? null : this.form.branch_2);
                        formData.append('tc_2', this.form.bn_2 === 'Nuestras Oficinas' ? null : this.form.tc_2);
                        formData.append('nc_2', this.form.bn_2 === 'Nuestras Oficinas' ? null : (this.form.nc_2).replace(/ /g, ''));
                        formData.append('amount_2', this.form.amount_2);
                        formData.append('quotes_2',  JSON.stringify(this.form.quotes_2));
                        formData.append('fee_2',  this.form.fee_2);
                        formData.append('fx_2',  this.form.fx_2);
                        formData.append('convert_2',  this.form.convert_2);
                        formData.append('cConvert_2',  this.form.cConvert_2);
                    }

                    if (this.form.Qty > 2) {
                        formData.append('account_3', this.form.account_3 ? this.form.account_3 : 0);
                        formData.append('name_3', this.form.name_3);
                        formData.append('td_3', this.form.td_3);
                        formData.append('nd_3', this.form.nd_3);
                        formData.append('bank_3', this.form.bank_3);
                        formData.append('branch_3',  this.form.bn_3 !== 'Nuestras Oficinas' ? null : this.form.branch_3);
                        formData.append('tc_3', this.form.bn_3 === 'Nuestras Oficinas' ? null : this.form.tc_3);
                        formData.append('nc_3', this.form.bn_3 === 'Nuestras Oficinas' ? null : (this.form.nc_3).replace(/ /g, ''));
                        formData.append('amount_3', this.form.amount_3);
                        formData.append('quotes_3',  JSON.stringify(this.form.quotes_3));
                        formData.append('fee_3',  this.form.fee_3);
                        formData.append('fx_3',  this.form.fx_3);
                        formData.append('convert_3',  this.form.convert_3);
                        formData.append('cConvert_3',  this.form.cConvert_3);
                    }

                    if (this.form.Qty > 3) {
                        formData.append('account_4', this.form.account_4 ? this.form.account_4 : 0);
                        formData.append('name_4', this.form.name_4);
                        formData.append('td_4', this.form.td_4);
                        formData.append('nd_4', this.form.nd_4);
                        formData.append('bank_4', this.form.bank_4);
                        formData.append('branch_4',  this.form.bn_4 !== 'Nuestras Oficinas' ? null : this.form.branch_4);
                        formData.append('tc_4', this.form.bn_4 === 'Nuestras Oficinas' ? null : this.form.tc_4);
                        formData.append('nc_4', this.form.bn_4 === 'Nuestras Oficinas' ? null : (this.form.nc_4).replace(/ /g, ''));
                        formData.append('amount_4', this.form.amount_4);
                        formData.append('quotes_4',  JSON.stringify(this.form.quotes_4));
                        formData.append('fee_4',  this.form.fee_4);
                        formData.append('fx_4',  this.form.fx_4);
                        formData.append('convert_4',  this.form.convert_4);
                        formData.append('cConvert_4',  this.form.cConvert_4);
                    }

                    if (this.form.Qty > 4) {
                        formData.append('account_5', this.form.account_5 ? this.form.account_5 : 0);
                        formData.append('name_5', this.form.name_5);
                        formData.append('td_5', this.form.td_5);
                        formData.append('nd_5', this.form.nd_5);
                        formData.append('bank_5', this.form.bank_5);
                        formData.append('branch_5',  this.form.bn_5 !== 'Nuestras Oficinas' ? null : this.form.branch_5);
                        formData.append('tc_5', this.form.bn_5 === 'Nuestras Oficinas' ? null : this.form.tc_5);
                        formData.append('nc_5', this.form.bn_5 === 'Nuestras Oficinas' ? null : (this.form.nc_5).replace(/ /g, ''));
                        formData.append('amount_5', this.form.amount_5);
                        formData.append('quotes_5',  JSON.stringify(this.form.quotes_5));
                        formData.append('fee_5',  this.form.fee_5);
                        formData.append('fx_5',  this.form.fx_5);
                        formData.append('convert_5',  this.form.convert_5);
                        formData.append('cConvert_5',  this.form.cConvert_5);
                    }

                    axios.post('/api/request/save', formData, {
                        headers: {'Content-Type': 'multipart/form-data'}
                    }).then(res => {
                        this.$toast.success(res.data.status, { icon: 'mdi-check-circle' });
                        this.$emit('confirm');
                    }).catch(err => {
                        this.handleErrors(err.response.data.errors);
                    }).then(() => {
                        this.loading = false;
                    })
                }
            } else {
                this.$toast.error('El valor depositado no concuerda con la suma de los valores a transferir.', {
                    timeout: 6000,
                    icon: 'mdi-information',
                    dismissable: false,
                    showClose: true
                });
            }
        }
    }
}
</script>
