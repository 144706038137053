<template>
    <v-container fluid class="mb-12 mb-lg-0">
        <v-row justify="center">
            <v-col md="12">
                <v-card width="100%">
                    <v-card-title class="d-block text-center font-weight-bold primary warning--text py-2" style="word-break: normal">REPORTE GENERAL</v-card-title>
                    <v-btn bottom color="warning primary--text" depressed fab absolute right small @click="getReport(1)">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col md="12">
                <v-card>
                    <v-card-text class="pb-0">
                        <v-row justify="center">
                            <v-col cols="12" sm="6" md="3">
                                <v-select
                                    :label="labels.destiny"
                                    :items="countries"
                                    name="destiny"
                                    v-model="form.destiny"
                                    :error-messages="errors.destiny"
                                    :disabled="loading"
                                    :rules="[]"
                                    @select="clearErrors('destiny')"
                                    prepend-icon="mdi-map-marker-down"
                                    item-text="name"
                                    item-value="code">
                                    <template v-slot:selection="{ item }">
                                        <v-flex>
                                            <v-avatar size="28px">
                                                <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                            </v-avatar>
                                            <span class='ml-1'>{{ item.name }}</span>
                                        </v-flex>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <v-list-item-avatar>
                                            <img :src="'/img/country/' + item.code + '.png'" :alt="(item.name).toLowerCase()" />
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="item.name"/>
                                        </v-list-item-content>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-dialog
                                    ref="menu1"
                                    v-model="menu.start"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :label="labels.start"
                                            v-model="start"
                                            name="start"
                                            prepend-icon="mdi-calendar"
                                            :error-messages="errors.start"
                                            :rules="[rules.req('start')]"
                                            :disabled="loading"
                                            @input="clearErrors('start')"
                                            hide-details
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="form.start"
                                        scrollable
                                        :max="maxDate"
                                        :min="minDate"
                                        @change="menu.start = false"
                                    ></v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-dialog
                                    ref="menu2"
                                    v-model="menu.end"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :label="labels.end"
                                            v-model="end"
                                            name="end"
                                            prepend-icon="mdi-calendar"
                                            :error-messages="errors.end"
                                            :rules="[rules.req('end')]"
                                            :disabled="loading"
                                            @input="clearErrors('end')"
                                            hide-details
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="form.end"
                                        scrollable
                                        :max="maxDate"
                                        :min="minDate"
                                        @change="menu.end = false"
                                    ></v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-btn color="primary" block large depressed @click="getReport">
                                    <v-icon>mdi-filter</v-icon>&nbsp;&nbsp;Filtrar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details/>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" :loading="loading"
                                  :no-data-text="noDataText" :footer-props="footerProps">
                        <template v-slot:item.id="{ item }">
                            <strong>{{ item.id }}</strong>
                        </template>
                        <template v-slot:item.sender="{ item }">
                            <span style="text-transform: capitalize">{{ (item.sender).toLowerCase() }}</span>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span style="text-transform: capitalize">{{ (item.name).toLowerCase() }}</span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-btn color="primary" small depressed dark :to="'/history/transfer/' + item.id">
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import Form from "../../../helpers/form";

    export default {
        name: "General",
        mixins: [ Form ],
        props: [ 'user' ],
        data: () => ({
            search: '',
            headers: [
                { text: 'ID', value: 'id', sortable: false },
                { text: 'Enviador', value: 'sender', sortable: false },
                { text: 'Receptor', value: 'name', sortable: false },
                { text: 'Recibido', value: 'amount_send_text', align: 'end', sortable: false },
                { text: 'Pagado', value: 'amount_receiver_text', align: 'end', sortable: false },
                { text: 'Paises', value: 'countries', align: 'center', sortable: false },
                { text: 'Estado', value: 'status_name', sortable: false },
                { text: 'Fecha Inicial', value: 'created_at', sortable: false },
                { text: 'Fecha Final', value: 'updated_at', sortable: false },
                { text: 'Acción', value: 'action', align: 'center', filterable: false, sortable: false }
            ],
            desserts: [],
            loading: true,
            noDataText: 'No tienes transacciones registradas',
            footerProps: {
                itemsPerPageOptions: [50, 100, 250, 500, -1]
            },
            menu: {
                start: false,
                end: false
            },
            labels: {
                destiny: 'País Destino',
                start: 'Fecha Inicial',
                end: 'Fecha Final'
            },
            form: {
                destiny: '000',
                start: undefined,
                end: undefined
            },
            countries: [
                { name: 'Todos', code: '000' },
                { name: 'Brasil', code: 'BRA' },
                { name: 'Chile', code: 'CHL' },
                { name: 'Colombia', code: 'COL' },
                { name: 'Ecuador', code: 'ECU' },
                { name: 'Mexico', code: 'MEX' },
                { name: 'Panama', code: 'PAN' },
                { name: 'Peru', code: 'PER' },
                { name: 'Estados Unidos', code: 'USA' },
                { name: 'Venezuela', code: 'VEN' }
            ],
            minDate: undefined,
            maxDate: undefined
        }),
        mounted () {
            this.form.destiny = this.$route.query.country || '000';
            this.getDate();
            this.getReport();
        },
        computed: {
            start() {
                return this.formatDate(this.form.start)
            },
            end() {
                return this.formatDate(this.form.end)
            },
        },
        methods: {
            getReport(v) {
                clearTimeout(this.timeout);
                this.loading = true;
                if (v === 1) { this.desserts = []; }
                axios.post('/api/reports/general', this.form).then((res) => {
                    this.desserts = res.data.transfers;
                }).catch(err => {
                    this.handleErrors(err.response.data.errors);
                }).then(() => {
                    this.loading = false;
                });
                this.timeout = setTimeout(this.getReport, 120000);
            },
            getDate() {
                const now = new Date()
                const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
                today.setDate(today.getDate())
                // 5 años menos
                const minDate = new Date(today)
                minDate.setFullYear(minDate.getFullYear() - 5)
                // 1 mes menos
                const start = new Date(today)
                start.setMonth(start.getMonth() - 1)

                this.minDate = minDate.toISOString().slice(0, 10);
                this.maxDate = today.toISOString().slice(0, 10);
                this.form.start = start.toISOString().slice(0, 10);
                this.form.end = today.toISOString().slice(0, 10);
            },
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}/${month}/${year}`
            }
        }
    }
</script>

<style scoped>

</style>
